import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./NeedHandChild.module.css";
import arrowright from "../../assets/arrow-right.png";

const NeedHandChild = ({ title, desc1, desc2, desc3, path, question }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  const handleNavigate = () => {
    // Passing the 'question' as part of the state
    navigate(path, { state: { question } });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  return (
    <div
      onClick={handleNavigate}
      ref={containerRef}
      style={{
       
        position: 'relative',
        transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
        opacity: isVisible ? 1 : 0,
        transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
        cursor:'pointer'
      }}
      className={classes.need_hand_child}
    >
      <h3>{title}</h3>
      <p>{desc1}</p>
      <p>{desc2}</p>
      <p>{desc3}</p>

      <img
        onClick={handleNavigate}
      // onClick={() => navigate(path)} 
      src={arrowright} alt={title} style={{ cursor: "pointer",}}  />
    </div>
  );
};

export default NeedHandChild;

import React from 'react';
import classes from './BestModal.module.css';
import close from '../../../asset/cross-new.png'
import { IoIosCloseCircle } from "react-icons/io";

const BestModal = ({ onClose, children }) => {
    return (
        <div className={classes.modalBackdrop} onClick={onClose}>
            <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                 {/* <img onClick={onClose} src={close} alt="close" className={classes.closeButton} />  */}
                <IoIosCloseCircle className={classes.closeButton} onClick={onClose} />
                {children}
            </div>
        </div>
    );
};

export default BestModal;

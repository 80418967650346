import React from 'react';
import classes from './AboutUsSimplify.module.css';

import data from '../../../asset-video/CoinsAnimation.mp4'

const AboutUsSimplify = () => {
    return (
        <div className={classes.parent}>
            <h2>Let's simplify all things money</h2>
            <p>Our global mission is for every person and business to do all things money — spending, saving, investing, borrowing, managing, and more — in just a few taps.</p>

            <video
                width="50%"
                height="auto"
                // ref={videoRef}
                key={data}
                muted
                playsInline
                // onEnded={handleVideoEnd}
                style={{
                    outline: 'none',
                    pointerEvents: 'none', // Prevent interaction
                  }}
                autoPlay
                loop
            >
                <source src={data} type="video/mp4" />
            </video>
        </div>
    )
}

export default AboutUsSimplify;
import React from 'react'
import classes from './CardAnimation.module.css';
// import SimpleAnimation from '../../../component/simple-animation/SimpleAnimation';
// import horizontalCard from '../../../assets-video/card-horizontal-vid.mp4';
import SimpleAnimation from '../../../components/simple-animation/SimpleAnimation';
import horizontalCard from '../../../asset-video/cardPik.mp4';

const CardAnimation = () => {
  return (
    <div className={classes.card_animation}>
      <div className={classes.card_animation_child}>
        <h2 className={classes.cards_again_card_title1}>Cards with so many colors.</h2>
        <h2 className={classes.cards_again_card_title2}>With so many different choices,Match your card with your style.</h2>
        <p className={classes.cards_animation_para}>
          Rival has partnered with Visa Asia to introduce the Rival VISA card, a collaboration that, along
          with contributions from other third parties, enables us to engage a diverse user base both locally
          and globally. Our mission at Rival is to provide a borderless, frictionless, and limitless financial
          experience for everyone.
        </p>
      </div>



      <SimpleAnimation data={horizontalCard} className={classes.cardSection2} loop={false}
        style={{ paddingTop: "38px" }}

      />

      {/* <div style={{poistion: 'relative', height: '100vh', width: '100%', background: 'blue'}} /> */}

    </div>
  )
}

export default CardAnimation;
import React from 'react';
import classes from './InternationalBtn.module.css';

const internationalBtn = ({title, style}) => {
  return (
    <button style={style} className={classes.international_btn}
    onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
    >{title}</button>
  )
}

export default internationalBtn;

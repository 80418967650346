import React, { useState } from "react";
import { toast } from "react-toastify";
import classes from "./AboutUsWorktogether.module.css";
import { Button } from "@mui/material";

const AboutUsWorktogether = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({ name: "", email: "", message: "" });
  const [show, setShow] = useState(false);
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    message: false,
  });
  const validateForm = () => {
    const newErrors = {};
    // Validate name
    const nameRegex = /^[A-Za-z\s]+$/; // Only letters and spaces
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    } else if (!nameRegex.test(formData.name.trim())) {
      newErrors.name = "Name should contain only letters and spaces.";
    }
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address.";
    }
    // Validate message
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     // If valid, submit the form
  //     toast.success("Form submitted successfully!");
  //     setFormData({ name: "", email: "", message: "" }); // Reset form
  //     setTouched({ name: false, email: false, message: false }); // Reset touched state
  //   }
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   emailjs
  //     .send(
  //       'service_dqtog1i', // Replace with your EmailJS service ID
  //       'template_fjbsveh', // Replace with your EmailJS template ID
  //       {
  //         name: formData?.name,
  //         email: formData?.email,
  //         message: formData?.message,
  //       },
  //       'YOUR_USER_ID' // Replace with your EmailJS user ID
  //     )
  //     .then(
  //       (result) => {
  //         console.log('Email sent:', result.text);
  //         alert('Email sent successfully!');
  //       },
  //       (error) => {
  //         console.error('Error sending email:', error.text);
  //         alert('Failed to send email.');
  //       }
  //     );
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Replace with your EmailJS API key
    const API_KEY = "J1pCRQb1BYqGFIk79"; // Your API Key here
    const SERVICE_ID = "service_dqtog1i"; // Your EmailJS service ID
    const TEMPLATE_ID = "template_fjbsveh"; // Your EmailJS template ID
    const emailData = {
      service_id: SERVICE_ID,
      template_id: TEMPLATE_ID,
      user_id: API_KEY,
      template_params: {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      },
    };
    fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Email sent:", result);
        setShow(true);
        // alert("Email sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      })
      .catch((error) => {
        setShow(true);
        console.error("Error sending email:", error);
        // alert("Email sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Re-validate the specific field when its value changes
    if (touched[name]) {
      validateForm();
    }
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
    // Validate the specific field on blur
    validateForm();
  };
  // Determine if there are any errors
  const hasErrors = Object.keys(errors).some((key) => errors[key]);
  return (
    <div style={{ backgroundColor: "#F7F7F7F7"}}>

    <div className={classes.parent}>
      <div className={classes.left}>
        <h1>Let’s Work Together</h1>
      </div>
      <div className={classes.right}>
        <form onSubmit={handleSubmit}>
          <div className={classes.right_top}>
            <input
              name="name"
              placeholder="Name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <input
              name="email"
              placeholder="Email"
              type="text"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <textarea
            name="message"
            placeholder=""
            rows="4"
            value={formData.message}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {/* {touched.message && errors.message && (
            <p className={classes.error}>{errors.message}</p>
          )} */}
          <div className={classes.right_bottom}>
            <p
              style={{
                color: hasErrors ? "red" : "#2FC780", // Set text color to red if there are errors
              }}
            >
              {hasErrors && "Please enter valid details."}
            </p>
            {show && (
              <p
                style={{
                  color: hasErrors ? "red" : "#2FC780", // Set text color to red if there are errors
                }}
              >
                {"Thank you, we’ll get back to you soon."}
              </p>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                padding: "10px 25px",
                marginTop: "16px",
                backgroundColor: "#246BFD",
                borderRadius: "50px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "black",
                  color: "#fff",
                },
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};
export default AboutUsWorktogether;
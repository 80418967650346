import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown, FaBars } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";

import rival from "../../asset/rival-text.png";

import dollar from "../../asset/header-logo/dollar.png";
import dollarhalf from "../../asset/header-logo/dollar-half.png";
import wallet from "../../asset/header-logo/wallet.png";
import wallethalf from "../../asset/header-logo/wallet-half.png";
import transfer from "../../asset/header-logo/transfer.png";
import transferhalf from "../../asset/header-logo/transfer-half.png";
import leftright from "../../asset/header-logo/leftright.png";
import leftrighthalf from "../../asset/header-logo/leftright-half.png";
import plan from "../../asset/header-logo/plans.png";
import planhalf from "../../asset/header-logo/plans-half.png";

import I from "../../asset/header-logo/I.png";
import Ihalf from "../../asset/header-logo/i-half.png";
import headphone from "../../asset/header-logo/headphone.png";
import headphonehalf from "../../asset/header-logo/headphone-half.png";
import triangle from "../../asset/header-logo/triangle.png";
import trianglehalf from "../../asset/header-logo/triangle-half.png";
import book from "../../asset/header-logo/book.png";
import bookhalf from "../../asset/header-logo/book-half.png";

const Header = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [hoveredContent, setHoveredContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMouseEnterHeader = (content) => {
    setHoveredContent(content);
  };

  const handleMouseLeaveHeader = () => {
    setTimeout(() => {
      setHoveredContent("");
    }, 200);
  };
  const handleClick = () => {
    setHoveredContent("");
  };

  return (
    <header
      className={`${styles.header} ${isScrolled ? styles.scrolled : ""} ${hoveredContent ? styles.expanded : ""
        }`}
      onMouseLeave={handleMouseLeaveHeader}
    >
      <div className={styles.header_child}>
        <img
          onClick={() => navigate("/")}
          src={rival}
          alt="rival"
          style={{
            width: "70px",
            height: "auto",
            marginLeft: "40px",
            cursor: "pointer",
          }}
        />
        <nav className={styles.nav}>
          <ul>
            <li
              onClick={handleClick}
              className={styles.items_center}
              onMouseEnter={() => handleMouseEnterHeader("personal")}
            >
              <Link to="/Personal" className={styles.hover}>
                Personal
              </Link>
              {/* <Link to='/personal' onClick={handleReload} className={styles.hover}>Personal</Link> */}
              <FaAngleDown color="#fff" size={15} />
            </li>
            <li onClick={handleClick}>
              <Link to="/crypto" className={styles.hover}>
                Crypto
              </Link>
            </li>
            <li
              onClick={handleClick}
              className={styles.items_center}
              onMouseEnter={() => handleMouseEnterHeader("company")}
            >
              <Link to="/" className={styles.hover}>
                Company
              </Link>
              <FaAngleDown color="#fff" size={15} />
            </li>
          </ul>
        </nav>
        <button className={styles.header_button}
          onClick={() => navigate("/help-support")}
        >Help & Support</button>
        <div onClick={toggleContent} className={styles.header_toggle_content}>
          {isOpen ? (
            <FaBars size={30} color="#246BFD" />
          ) : (
            <FaBars color="#246BFD" size={30} />
          )}
        </div>
      </div>
      {hoveredContent === "personal" && <PersonalChild onClick={handleClick} />}
      {hoveredContent === "company" && <CompanyChild onClick={handleClick} />}
      {isOpen && (
        <div className={styles.small_nav}>
          <ul>
            <li onClick={handleClick}>
              <Link to="/Personal" className={styles.hover}>
                Personal
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/cards" className={styles.hover}>
                Cards
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/transfer" className={styles.hover}>
                Transfers
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/compare" className={styles.hover}>
                Compare Plans
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/investing" className={styles.hover}>
                Investing
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/aboutUs" className={styles.hover}>
                About Us
              </Link>
            </li>
            <li onClick={handleClick}>
              <Link to="/help-support" className={styles.hover}>
                Help & Support
              </Link>
            </li>
            <button onClick={() => navigate('/help-support')} className={styles.header_small_button}>Help & Support</button>
          </ul>
        </div>
      )}
    </header>
  );
};

const PersonalChild = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <div onClick={onClick} className={styles.header_option}>
      {/* <OptionChild onClick={onClick} desc="Savings" logo={dollar} logohalf={dollarhalf} /> */}
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/Personal");
        }}
        desc="Personal"
        logo={dollar}
        logohalf={dollarhalf}
        style={{ paddingRight: "4rem" }}
      />
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/cards");
        }}
        desc="Cards"
        logo={wallet}
        logohalf={wallethalf}
        style={{ paddingRight: "4rem", marginLeft: "-2rem" }}
      />
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/transfer");
        }}
        desc="Transfers"
        logo={transfer}
        logohalf={transferhalf}
        style={{ paddingRight: "4rem", marginLeft: "-2rem" }}
      />
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/compare");
        }}
        desc="Compare Plans"
        logo={plan}
        logohalf={planhalf}
        style={{ paddingRight: "4rem", marginLeft: "-2rem" }}
      />
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/investing");
        }}
        desc="Investing"
        logo={leftright}
        logohalf={leftrighthalf}
        style={{ border: "none", marginLeft: "-2rem" }}
      />
    </div>
  );
};
const CompanyChild = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <div onClick={onClick} className={styles.header_option}>
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/aboutUs");
        }}
        desc="About Us"
        logo={I}
        logohalf={Ihalf}
      />
      <OptionChild
        onClick={() => {
          onClick();
          navigate("/help-support");
        }}
        desc="Help & Support"
        logo={headphone}
        logohalf={headphonehalf}
        style={{ border: "none" }}
      />
    </div>
  );
};

const OptionChild = ({ onClick, desc, logo, logohalf, style }) => {
  const [imageSrc, setImageSrc] = useState(logo);
  return (
    <div
      onClick={onClick}
      style={style}
      className={styles.header_option_child}
      onMouseEnter={() => setImageSrc(logohalf)}
      onMouseLeave={() => setImageSrc(logo)}
    >
      <img
        src={imageSrc}
        alt="img"
        style={{ width: "auto", height: "50px", marginBottom: "40px" }}
      />
      <label
        onClick={onClick}
        className={`${styles.options_child_label} ${styles.hover}`}
      >
        {desc}
      </label>
    </div>
  );
};

export default Header;

import React from 'react';
import classes from './Modal6.module.css';

const Modal6 = ({ desc, img }) => {
    return (
        <div className={classes.modal_parent}>
            <p className={classes.modal_desc} dangerouslySetInnerHTML={{ __html: desc }} />
            <img src={img} alt="img" className={classes.modal_image} />
        </div>
    )
}

export default Modal6
import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import HelpSupportHeader from "../help-support-header/HelpSupportHeader";
import classes from "./HelpSupportMain.module.css";
import Help1 from "../Help1";
import { useEffect, useState } from "react";
import { HelpSupportMains } from "../../../Track";
const HelpSupportMain = () => {
  const location = useLocation();
  const { question } = location?.state || {}; // Access the state passed with navigate
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  console.log("questionquestion", question);
  const [answer, setAnswer] = useState("");
  const [activeIndex, setActiveIndex] = useState(null); // State to store the active question index

  useEffect(() => {
    if (question && question.length > 0) {
      setActiveIndex(0); // Set the first question as active
      setAnswer(question[0]); // Set the answer of the first question
      setShow(true); // Show the answer of the first question
    }
  }, [question]);
  useEffect(() => {
    HelpSupportMains();
  }, []);
  return (
    <div style={{ backgroundColor: "#F5F5F7" }}>
      <HelpSupportHeader />

      <div className={classes.home_parent}>
        <div className={classes.home_side_nav}>
          <nav>
            <ul className={classes.home_side_list}>
              {question &&
                question?.map((item, index) => (
                  <li key={index}>
                    <div
                      onClick={() => {
                        setActiveIndex(index); // Set the clicked question as active
                        setShow(true); // Show the modal or perform the desired action
                        setAnswer(item); // Store the answer in the state
                      }}
                      className={`${classes.inactiveLink} ${
                        activeIndex === index ? classes.activeLinkButton : ""
                      }`} // Apply active class
                      // className={classes.inactiveLink} // Add your styles here
                      role="button"
                      tabIndex={0} // Makes the div focusable for accessibility
                    >
                      <label
                        onClick={() => {
                          setActiveIndex(index); // Set the clicked question as active
                          setShow(true); // Show the modal or perform the desired action
                          setAnswer(item); // Store the answer in the state
                        }}
                      >
                        {item?.question?.length > 28
                          ? `${item?.question?.slice(0, 28)}...`
                          : item.question}
                      </label>
                    </div>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
        {show ? (
          <div className={classes.home_main}>
            <div className={classes.child}>
              <h4>{answer?.question}</h4>
              <p>
                {answer?.answer.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>

              {/* <p>{answer?.answer}</p> */}
            </div>
          </div>
        ) : (
          <div className={classes.home_main}>
            <div className={classes.child}>
              <h4>Please select Question</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpSupportMain;

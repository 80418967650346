const root_path = "./countryimages";
export const CountryCode = [
  {
    label: "Albania",
    value: "+355",
    icon: require(`${root_path}/al.png`),
  },
  {
    label: "Andorra",
    value: "+376",
    icon: require(`${root_path}/ad.png`),
  },
  {
    label: "Austria",
    value: "+61",
    icon: require(`${root_path}/at.png`),
  },
  // {
  //   label: "United States (US)",
  //   value: "+1",
  //   icon: require(`${root_path}/us.png`),
  //   // icon: require(`${root_path}/us.png`),
  // },
  // {
  //   label: "Afghanistan",
  //   value: "+93",
  //   icon: require(`${root_path}/af.png`),
  // },
  // {
  //   label: "Albania",
  //   value: "+355",
  //   icon: require(`${root_path}/al.png`),
  // },
  // {
  //   label: "Algeria",
  //   value: "+213",
  //   icon: require(`${root_path}/dz.png`),
  // },
  // {
  //   label: "American Samoa",
  //   value: "+1684",
  //   icon: require(`${root_path}/as.png`),
  // },
  // {
  //   label: "Andorra",
  //   value: "+376",
  //   icon: require(`${root_path}/ad.png`),
  // },
  // {
  //   label: "Angola",
  //   value: "+244",
  //   icon: require(`${root_path}/ao.png`),
  // },
  // {
  //   label: "Anguilla",
  //   value: "+1264",
  //   icon: require(`${root_path}/ai.png`),
  // },
  // {
  //   label: "Antigua and Barbuda",
  //   value: "+1268",
  //   icon: require(`${root_path}/ag.png`),
  // },
  // {
  //   label: "Argentina",
  //   value: "+54",
  //   icon: require(`${root_path}/ar.png`),
  // },
  // {
  //   label: "Armenia",
  //   value: "+374",
  //   icon: require(`${root_path}/am.png`),
  // },
  // {
  //   label: "Aruba",
  //   value: "+297",
  //   icon: require(`${root_path}/aw.png`),
  // },
  // {
  //   label: "Australia",
  //   value: "+61",
  //   icon: require(`${root_path}/au.png`),
  // },
  // {
  //   label: "Austria",
  //   value: "+43",
  //   icon: require(`${root_path}/at.png`),
  // },
  // {
  //   label: "Azerbaijan",
  //   value: "+994",
  //   icon: require(`${root_path}/az.png`),
  // },
  // {
  //   label: "Bahamas",
  //   value: "+1242",
  //   icon: require(`${root_path}/bs.png`),
  // },
  // {
  //   label: "Bahrain‬‎",
  //   value: "+973",
  //   icon: require(`${root_path}/bh.png`),
  // },
  // {
  //   label: "Bangladesh",
  //   value: "+880",
  //   icon: require(`${root_path}/bd.png`),
  // },
  // {
  //   label: "Barbados",
  //   value: "+1246",
  //   icon: require(`${root_path}/bb.png`),
  // },
  // {
  //   label: "Belarus",
  //   value: "+375",
  //   icon: require(`${root_path}/by.png`),
  // },
  {
    label: "Belgium",
    value: "+32",
    icon: require(`${root_path}/be.png`),
  },
  // {
  //   label: "Belize",
  //   value: "+501",
  //   icon: require(`${root_path}/bz.png`),
  // },
  // {
  //   label: "Benin",
  //   value: "+229",
  //   icon: require(`${root_path}/bj.png`),
  // },
  // {
  //   label: "Bermuda",
  //   value: "+1441",
  //   icon: require(`${root_path}/bm.png`),
  // },
  // {
  //   label: "Bhutan",
  //   value: "+975",
  //   icon: require(`${root_path}/bt.png`),
  // },
  // {
  //   label: "Bolivia",
  //   value: "+591",
  //   icon: require(`${root_path}/bo.png`),
  // },
  {
    label: "Bosnia and Herzegovina",
    value: "+387",
    icon: require(`${root_path}/ba.png`),
  },
  // {
  //   label: "Botswana",
  //   value: "+267",
  //   icon: require(`${root_path}/bw.png`),
  // },
  // {
  //   label: "Brazil",
  //   value: "+55",
  //   icon: require(`${root_path}/br.png`),
  // },
  // {
  //   label: "British Indian Ocean Territory",
  //   value: "+246",
  //   icon: require(`${root_path}/io.png`),
  // },
  // {
  //   label: "British Virgin Islands",
  //   value: "+1284",
  //   icon: require(`${root_path}/vg.png`),
  // },
  // {
  //   label: "Brunei",
  //   value: "+673",
  //   icon: require(`${root_path}/bn.png`),
  // },
  {
    label: "Bulgaria",
    value: "+359",
    icon: require(`${root_path}/bg.png`),
  },
  // {
  //   label: "Burkina Faso",
  //   value: "+226",
  //   icon: require(`${root_path}/af.png`),
  // },
  // {
  //   label: "Burundi",
  //   value: "+257",
  //   icon: require(`${root_path}/bi.png`),
  // },
  // {
  //   label: "Cambodia",
  //   value: "+855",
  //   icon: require(`${root_path}/af.png`),
  // },
  // {
  //   label: "Cameroon",
  //   value: "+237",
  //   icon: require(`${root_path}/cm.png`),
  // },
  // {
  //   label: "Canada",
  //   value: "+1",
  //   icon: require(`${root_path}/ca.png`),
  // },
  // {
  //   label: "Cape Verde",
  //   value: "+238",
  //   icon: require(`${root_path}/cv.png`),
  // },
  // {
  //   label: "Caribbean Netherlands",
  //   value: "+599",
  //   icon: require(`${root_path}/bq.png`),
  // },
  // {
  //   label: "Cayman Islands",
  //   value: "+1345",
  //   icon: require(`${root_path}/ky.png`),
  // },
  // {
  //   label: "Central African Republic",
  //   value: "+236",
  //   icon: require(`${root_path}/cf.png`),
  // },
  // {
  //   label: "Chad (Tchad)",
  //   value: "+235",
  //   icon: require(`${root_path}/td.png`),
  // },
  // {
  //   label: "Chile",
  //   value: "+56",
  //   icon: require(`${root_path}/cl.png`),
  // },
  // {
  //   label: "China",
  //   value: "+86",
  //   icon: require(`${root_path}/cn.png`),
  // },
  // {
  //   label: "Christmas Island",
  //   value: "+61",
  //   icon: require(`${root_path}/cx.png`),
  // },
  // {
  //   label: "Cocos Islands",
  //   value: "+61",
  //   icon: require(`${root_path}/cc.png`),
  // },
  // {
  //   label: "Colombia",
  //   value: "+57",
  //   icon: require(`${root_path}/co.png`),
  // },
  // {
  //   label: "Comoros‬‎",
  //   value: "+269",
  //   icon: require(`${root_path}/km.png`),
  // },
  // {
  //   label: "Congo",
  //   value: "+243",
  //   icon: require(`${root_path}/cd.png`),
  // },
  // {
  //   label: "Congo",
  //   value: "+242",
  //   icon: require(`${root_path}/cg.png`),
  // },
  // {
  //   label: "Cook Islands",
  //   value: "+682",
  //   icon: require(`${root_path}/ck.png`),
  // },
  // {
  //   label: "Costa Rica",
  //   value: "+506",
  //   icon: require(`${root_path}/cr.png`),
  // },
  {
    label: "Croatia",
    value: "+385",
    icon: require(`${root_path}/hr.png`),
  },
  // {
  //   label: "Cuba",
  //   value: "+53",
  //   icon: require(`${root_path}/cu.png`),
  // },
  // {
  //   label: "Curaçao",
  //   value: "+599",
  //   icon: require(`${root_path}/cw.png`),
  // },
  {
    label: "Cyprus",
    value: "+357",
    icon: require(`${root_path}/cy.png`),
  },
  {
    label: "Czech Republic",
    value: "+420",
    icon: require(`${root_path}/cz.png`),
  },
  {
    label: "Denmark",
    value: "+45",
    icon: require(`${root_path}/dk.png`),
  },
  // {
  //   label: "Djibouti",
  //   value: "+253",
  //   icon: require(`${root_path}/dj.png`),
  // },
  // {
  //   label: "Dominica",
  //   value: "+1767",
  //   icon: require(`${root_path}/dm.png`),
  // },
  // {
  //   label: "Dominican Republic",
  //   value: "+1",
  //   icon: require(`${root_path}/do.png`),
  // },
  // {
  //   label: "Ecuador",
  //   value: "+593",
  //   icon: require(`${root_path}/ec.png`),
  // },
  // {
  //   label: "Egypt‬‎",
  //   value: "+20",
  //   icon: require(`${root_path}/eg.png`),
  // },
  // {
  //   label: "El Salvador",
  //   value: "+503",
  //   icon: require(`${root_path}/sv.png`),
  // },
  // {
  //   label: "Equatorial Guinea",
  //   value: "+240",
  //   icon: require(`${root_path}/gq.png`),
  // },
  // {
  //   label: "Eritrea",
  //   value: "+291",
  //   icon: require(`${root_path}/er.png`),
  // },
  {
    label: "Estonia",
    value: "+372",
    icon: require(`${root_path}/ee.png`),
  },
  // {
  //   label: "Ethiopia",
  //   value: "+251",
  //   icon: require(`${root_path}/et.png`),
  // },
  // {
  //   label: "Falkland Islands",
  //   value: "+500",
  //   icon: require(`${root_path}/fk.png`),
  // },
  // {
  //   label: "Faroe Islands",
  //   value: "+298",
  //   icon: require(`${root_path}/fo.png`),
  // },
  // {
  //   label: "Fiji",
  //   value: "+679",
  //   icon: require(`${root_path}/fj.png`),
  // },
  {
    label: "Finland",
    value: "+358",
    icon: require(`${root_path}/fi.png`),
  },
  {
    label: "France",
    value: "+33",
    icon: require(`${root_path}/fr.png`),
  },
  // {
  //   label: "French Guiana",
  //   value: "+594",
  //   icon: require(`${root_path}/gf.png`),
  // },
  // {
  //   label: "French Polynesia",
  //   value: "+689",
  //   icon: require(`${root_path}/pf.png`),
  // },
  // {
  //   label: "Gabon",
  //   value: "+241",
  //   icon: require(`${root_path}/ga.png`),
  // },
  // {
  //   label: "Gambia",
  //   value: "+220",
  //   icon: require(`${root_path}/gm.png`),
  // },
  // {
  //   label: "Georgia",
  //   value: "+995",
  //   icon: require(`${root_path}/ge.png`),
  // },
  {
    label: "Germany",
    value: "+49",
    icon: require(`${root_path}/de.png`),
  },
  // {
  //   label: "Ghana",
  //   value: "+233",
  //   icon: require(`${root_path}/gh.png`),
  // },
  // {
  //   label: "Gibraltar",
  //   value: "+350",
  //   icon: require(`${root_path}/gi.png`),
  // },
  {
    label: "Greece",
    value: "+30",
    icon: require(`${root_path}/gr.png`),
  },
  // {
  //   label: "Greenland",
  //   value: "+299",
  //   icon: require(`${root_path}/gr.png`),
  // },
  // {
  //   label: "Grenada",
  //   value: "+1473",
  //   icon: require(`${root_path}/gd.png`),
  // },
  // {
  //   label: "Guadeloupe",
  //   value: "+590",
  //   icon: require(`${root_path}/gp.png`),
  // },
  // {
  //   label: "Guam",
  //   value: "+1671",
  //   icon: require(`${root_path}/gu.png`),
  // },
  // {
  //   label: "Guatemala",
  //   value: "+502",
  //   icon: require(`${root_path}/gt.png`),
  // },
  // {
  //   label: "Guernsey",
  //   value: "+44",
  //   icon: require(`${root_path}/gg.png`),
  // },
  // {
  //   label: "Guinea",
  //   value: "+224",
  //   icon: require(`${root_path}/gn.png`),
  // },
  // {
  //   label: "Guinea-Bissau",
  //   value: "+245",
  //   icon: require(`${root_path}/gw.png`),
  // },
  // {
  //   label: "Guyana",
  //   value: "+592",
  //   icon: require(`${root_path}/gy.png`),
  // },
  // {
  //   label: "Haiti",
  //   value: "+509",
  //   icon: require(`${root_path}/ht.png`),
  // },
  // {
  //   label: "Honduras",
  //   value: "+504",
  //   icon: require(`${root_path}/hn.png`),
  // },
  // {
  //   label: "Hong Kong",
  //   value: "+852",
  //   icon: require(`${root_path}/hk.png`),
  // },
  {
    label: "Hungary",
    value: "+36",
    icon: require(`${root_path}/af.png`),
  },
  {
    label: "Iceland",
    value: "+354",
    icon: require(`${root_path}/af.png`),
  },
  // {
  //   label: "India",
  //   value: "+91",
  //   icon: require(`${root_path}/in.png`),
  // },
  // {
  //   label: "Indonesia",
  //   value: "+62",
  //   icon: require(`${root_path}/id.png`),
  // },
  // {
  //   label: "Iran‬‎",
  //   value: "+98",
  //   icon: require(`${root_path}/ir.png`),
  // },
  // {
  //   label: "Iraq‬‎",
  //   value: "+964",
  //   icon: require(`${root_path}/iq.png`),
  // },
  {
    label: "Ireland",
    value: "+353",
    icon: require(`${root_path}/ie.png`),
  },
  // {
  //   label: "Isle of Man",
  //   value: "+44",
  //   icon: require(`${root_path}/im.png`),
  // },
  // {
  //   label: "Israel‬‎",
  //   value: "+972",
  //   icon: require(`${root_path}/il.png`),
  // },
  {
    label: "Italy",
    value: "+39",
    icon: require(`${root_path}/it.png`),
  },
  // {
  //   label: "Jamaica",
  //   value: "+1876",
  //   icon: require(`${root_path}/af.png`),
  // },
  // {
  //   label: "Japan",
  //   value: "+81",
  //   icon: require(`${root_path}/jp.png`),
  // },
  {
    label: "Jersey",
    value: "+44",
    icon: require(`${root_path}/ge.png`),
  },
  {
    label: "Latvia",
    // countrycode: "LV",
    value: "+371",
    icon: require(`${root_path}/Country=latvia.png`),
    // flag: require(""),
  },
  {
    label: "Liechtenstein",
    value: "+423",
    icon: require(`${root_path}/Country=liechtenstein.png`),
  },
  {
    label: "Lithuania",
    value: "+370",
    icon: require(`${root_path}/Country=lithuania.png`),
  },
  {
    label: "Luxembourg",
    value: "+352",
    icon: require(`${root_path}/Country=luxembourg.png`),
  },
  {
    label: "Malta",
    value: "+352",
    icon: require(`${root_path}/Country=malta.png`),
  },
  {
    label: "Moldova",
    // value: "+352",
    icon: require(`${root_path}/Country=maldives.png`),
  },
  {
    label: "Monaco",
    // value: "+352",
    icon: require(`${root_path}/Country=morocco.png`),
  },
  {
    label: "Montenegro",
    // value: "+352",
    icon: require(`${root_path}/Country=montenegro.png`),
  },
  {
    label: "Netherlands",
    // value: "+352",
    icon: require(`${root_path}/Country=netherlands.png`),
  },
  {
    label:"North Macedonia",
    // value: "+352",
    icon: require(`${root_path}/Country=northerncyprus.png`),
  },
  {
    label:"Norway",
    // value: "+352",
    icon: require(`${root_path}/Country=norway.png`),
  },
  {
    label:"Poland",
    // value: "+352",
    icon: require(`${root_path}/Country=poland.png`),
  },
  {
    label:"Portugal",
    // value: "+352",
    icon: require(`${root_path}/Country=portugal.png`),
  },
  {
    label:"Romania",
    // value: "+352",
    icon: require(`${root_path}/Country=romania.png`),
  },
  {
    label:"San Marino",
    // value: "+352",
    icon: require(`${root_path}/Country=sanmarino.png`),
  },
  {
    label:"Serbia",
    // value: "+352",
    icon: require(`${root_path}/Country=serbia.png`),
  },
  {
    label:"Slovakia",
    // value: "+352",
    icon: require(`${root_path}/Country=slovakia.png`),
  },
  {
    label:"Spain",
    // value: "+352",
    icon: require(`${root_path}/Country=spain.png`),
  },
  {
    label:"Sweden",
    // value: "+352",
    icon: require(`${root_path}/Country=sweden.png`),
  },
  {
    label:"Switzerland",
    // value: "+352",
    icon: require(`${root_path}/Country=switzerland.png`),
  },
  {
    label:"Ukraine",
    // value: "+352",
    icon: require(`${root_path}/Country=ukraine.png`),
  },
  {
    label:"Vatican City",
    // value: "+352",
    icon: require(`${root_path}/Country=vaticancity.png`),
  },
  // {
  //   label: "Jordan‬‎",
  //   value: "+962",
  //   icon: require(`${root_path}/jo.png`),
  // },
  // {
  //   label: "Kazakhstan",
  //   value: "+7",
  //   icon: require(`${root_path}/kz.png`),
  // },
  // {
  //   label: "Kenya",
  //   value: "+254",
  //   icon: require(`${root_path}/ke.png`),
  // },
]
import React from 'react';
import classes from './Component22.module.css';
import vdo from '../../assets-video/stock-coin.mp4';
import img from '../../asset/stock-loop.png'
import SimpleAnimation from '../../components/simple-animation/SimpleAnimation';

const Component22 = () => {
    return (
        <div className={classes.component_22}>
            <img src={img} alt="first-img" className={classes.img_style} />
            <SimpleAnimation data={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736244817/Test/nwogykux9ea3jmjss6qd.mp4"} loop={true} style={{ position: "relative", zIndex: 1, marginBottom: "-5px" }} />
            <p>Invest <span>in</span> stocks <span>directly through</span> Rival, <span>allowing you to diversify your portfolio with ease. Our platform will offer</span> real-time <span>insights, seamless trading, and transparent fees, giving you full
                control over your investments. With Rival, investing becomes</span> accessible, <span>empowering you to grow your wealth in a way that aligns with your financial goals.</span></p>
        </div>
    )
}

export default Component22
import React from 'react';
import classes from './ModalTop.module.css';

const ModalTop = ({ ttl1, ttl2 }) => {
    return (
        <div className={classes.modalTop}>
            <label className={classes.ttl1}>{ttl1}</label>
            <p className={classes.ttl2}>{ttl2}</p>
        </div>
    )
}

export default ModalTop;
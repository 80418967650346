import React from 'react';
import classes from './Component18.module.css';
import { FaChevronRight } from 'react-icons/fa';

import ComparePhone from '../../components/compare-phone/ComparePhone';


import img1 from '../../asset/i-pro.png';
import img2 from '../../asset/i-plus.png';
import img3 from '../../asset/i-standard.png';

const data = [
  {
    id: 1,
    image: "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506537/xraija6ejfgpmrcynilg.avif",
    title: 'Pro',
    description: 'Tailored for the Pros.',
    price: 'from $5.99/mo',
    buttonText: 'Compare plans'
  },
  {
    id: 2,
    image: "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506636/wgunq3zopw8uevpp3jum.avif",
    title: 'Plus',
    description: 'Power up with plus',
    price: 'from $9.99/mo',
    buttonText: 'Compare plans'
  },
  {
    id: 3,
    image: "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506691/pzkux22fk9k3wp0kheo0.avif",
    title: 'Standard',
    description: 'Start your journey with standard.',
    price: 'from $5.99/mo',
    buttonText: 'Compare plans'
  }
]

const Component18 = () => {
  return (
    <div className={classes.financeapplication_parent}>
      <div className={classes.financeapplication_top}>
        <h2>Explore the lineup</h2>
        <div className={classes.financeapplication_top_right}>
          {/* <a href="">Compare all plans</a> */}
          {/* <FaChevronRight size={12} color="#246BFD" /> */}
        </div>
      </div>
      <div className={classes.compare_top_child}>
        {data.map((item) => (
          <ComparePhone
            key={item.id}
            image={item.image}
            title={item.title}
            description={item.description}
            price={item.price}
            buttonText={item.buttonText}
          />
        ))}
      </div>
    </div>
  )
}

export default Component18






    





import React from 'react';
import classes from './AboutUsEmpower.module.css';

import InternationalBtn from '../../../components/international-btn/InternationalBtn';

const AboutUsEmpower = () => {
    return (
        <div className={classes.parent}>
            <div className={classes.left}>
                <h2>Our Mission:</h2>
                <h1>Empower Your Money</h1>
                <p>We are living in a time of worry, fraud and uncertain futures. Rival is
                    looking to build a standard reliable and efficient model who seek stability across their money.</p>
                <p>Thats why we are here. Rival exists to remove all the day to day issues that users suffer from.</p>
                <p>We are building a platform so simple yet so advanced, that what ever you will need in Finance is
                    available in one spot.</p>
                <p>Our goal is for everyone to Empower their money through our services, in just a few taps. We are not
                    there, we are still far from it, however it is in our vision.</p>
                <InternationalBtn title="Join Now"
                onClick={() =>
                    window.open(
                      "https://apps.apple.com/tr/app/rival-finance/id6468575208",
                      "_blank"
                    )
                  }
                />
            </div>
            <div className={classes.right}>
            </div>
        </div>
    )
}

export default AboutUsEmpower;
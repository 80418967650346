import React from 'react';
import classes from './BestFirst.module.css';

const BestFirst = ({ title1, title2, desc1, desc2 }) => {
    return (
        <div className={classes.best_first}>
            <h5 className={classes.title_top}>{title1}</h5>
            <h2 className={classes.title_bottom}>{title2}</h2>
            <p className={classes.desc}>{desc1}</p>
            <p className={classes.desc}>{desc2}</p>
        </div >
    )
}

export default BestFirst
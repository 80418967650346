import React, { useEffect, useRef } from "react";
import "./Component24.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";

// import image1 from "../../asset/iphoneR.png";
// import image2 from "../../asset/iphoneW.png";
// import image3 from "../../asset/john-black-phones.png";

gsap.registerPlugin(ScrollTrigger);

const Component24 = () => {
  const workRef = useRef(null);

const image1 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504352/fmjbql6exet0mzwukazm.avif";

const image2 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504360/eyqz4qw2sbhibzetoifv.avif"

const image3 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504367/eed6cktcphsbxi4ezluy.avif"



  
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    const workInfoItems = document.querySelectorAll(".pc-work-photo-item");
    workInfoItems.forEach((item, index) => {
      item.style.zIndex = workInfoItems.length - index;
    });

    const animation = gsap.to(".pc-work-photo-item:not(:last-child)", {
      clipPath: "inset(0px 0px 100% 0px)",
      stagger: 0.5,
      ease: "none",
    });

    ScrollTrigger.create({
      trigger: workRef.current,
      start: "top top",
      end: "bottom bottom",
      animation: animation,
      scrub: 1,
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div style={{ background: "#000" }}>
      <section className="pc-work" ref={workRef}>
        <div className="pc-work-left">
          <div className="pc-work-info">
            <h2 className="pc-title2">Current Account</h2>
            <p className="pc-para">
              Rival’s current account is your<span> central hub</span> for
              managing all your funds in one place. Whether transferring money
              to your card account, making <span>global transfers</span>or
              connecting to the crypto world, Rival simplifies every transaction
              with <span>ease</span> and <span>transparency</span>
            </p>
            {/* <p className="pc-para">Rival’s current account is your<span>central hub <p>for managing all your funds in one place. Whether transferring money to your card account, making</p>  global transfers, or connecting to the crypto world, Rival simplifies every transaction with ease and transparency.</span> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface <span>without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available</span></p> */}
          </div>
          <div className="pc-work-info">
            <h2 className="pc-title2">Transfer</h2>
            <p className="pc-para">
              Rival makes money transfers
              <span> effortless</span> offering instant
              <span> user-to-user transfers</span>
              and seamless global transactions. With Rival, sending money is as{" "}
              <span> easy as sending a text—fast, secure,</span> and hassle-free.
              Whether transferring funds<span> locally</span> or{" "}
              <span>across borders,</span> Rival ensures a smooth experience
              with no unnecessary complications.
            </p>
          </div>
          <div className="pc-work-info">
            <h2 className="pc-title2">View Card</h2>
            <p className="pc-para">
            Rival puts control at your fingertips, allowing you to manage your cards effortlessly through the app.
              <span>
              Freeze
              </span>{" "}
              or
              <span> unfreeze</span>{" "}
              your card instantly for added security, view your card details anytime for online transactions, and
              <span> onitor your spending in real-time. </span>
              Rival ensures you’re always in charge, whether safeguarding your account or accessing your card information with ease.
            </p>
          </div>
        </div>
        <div className="pc-work-right">
          <div className="pc-work-right-bl">
            <div className="pc-work-photo">
              {[image1, image2, image3].map((src, index) => (
                <div className="pc-work-photo-item" key={index}>
                  <img src={src} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Component24;

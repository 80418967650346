import React, { useState } from "react";
import classes from "./Component16.module.css";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { CountryCode } from "../../assets/Country";
import { CountryA, CountryAme } from "../../assets/CountryAme";
import { CountryAsia, CountryB } from "../../assets/CountryAsia";
import { CountryAF } from "../../assets/CountryAF";

const Component16 = () => {
  return (
    <div className={classes.country_select_parent}>
      <h2>120+ Countries. 50+ Currencies</h2>
      <p>
        From Beirut to Montreal, and almost all countries in between, send money
        however you like. The world is in between your fingertips, literally...
      </p>
      <CountrySelectChild title="Europe" />
      <CountrySelectChild title="Americas" />
      <CountrySelectChild title="Asia Pacific" />
      <CountrySelectChild title="Africa" />
    </div>
  );
};

const CountrySelectChild = ({ title }) => {
  const [expanded, setExpanded] = useState(false);

  // const CountryCodes = expanded
  //   ? CountryCode?.slice(0,3)
  //   :CountryCode?.slice(0,3) ;

  // const CountryCodes = expanded
  //   ? CountryCode
  //   : title === "Asia Pacific"
  //   ? CountryAsia.slice(0, 3)
  //   : title === "Africa"
  //   ? CountryAF.slice(0, 3)
  //   :title === "Americas"
  //   ? CountryAF.slice(0, 3)
  //   : CountryCode.slice(0, 3);

  const CountryCodes = expanded
    ? title === "Asia Pacific"
      ? CountryAsia // Asia
      : title === "Africa"
      ? CountryAF // Africa
      : title === "Americas"
      ? CountryAme // Americas
      : CountryCode // ERUPE
    : (() => {
        const countryMapping = {
          "Asia Pacific": CountryAsia,
          Africa: CountryAF,
          Europe: CountryCode,
          Americas: CountryAme,
        };

        return (countryMapping[title] || CountryCode).slice(0, 3);
      })();

  return (
    <>
      <div className={classes.country_child_container}>
        <div className={classes.continent_child_name}>{title}</div>
        <ul className={classes.country_child_names}>
          {CountryCodes?.map((country, index) => (
            <li key={index} className={classes.country_child_name}>
              <img
                src={country.icon}
                alt={`${country.label} flag`}
                style={{
                  width: "30px", // Adjust size as needed
                  height: "30px",
                  borderRadius: "50%",
                  objectFit: "cover", // Ensures the image scales properly within the circle
                }}
              />
              <label>{country.label}</label>
            </li>
          ))}
        </ul>

        <div
          className={classes.country_child_toggle}
          onClick={() => setExpanded(!expanded)}
        >
          <label>{expanded ? "Show Less" : "Show More"}</label>
          {expanded ? (
            <FaChevronUp size="13px" />
          ) : (
            <FaChevronDown size="13px" />
          )}
        </div>
      </div>
    </>
  );
};

export default Component16;

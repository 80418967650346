import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Personal from "./pages/personal/Personal";
import Savings from "./pages/savings/Savings";
import Cards from "./pages/cards/Cards";
import Transfer from "./pages/transfer/Transfer";
import Investing from "./pages/investing/Investing";
import ComparePlans from "./pages/compare-plans/ComparePlans";
import Crypto from "./pages/crypto/Crypto";
import NeedHand from "./pages/need-hand/NeedHand";
import HelpSupportMain from "./build-component/help-support-section/help-support-main/HelpSupportMain";
import AboutUs from "./pages/about-us/AboutUs";
import Terms from "./pages/terms/Terms";
import RivalTerms from "./build-component/terms/rival-terms/RivalTerms";
import PartnersTerms from "./build-component/terms/partners-terms/PartnersTerms";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";

import Error from "./build-component/ErrorPage/Error";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

// Layout Component
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/Personal"
            exact
            element={
              <Layout>
                <Personal />
              </Layout>
            }
          />
          <Route
            path="/cards"
            exact
            element={
              <Layout>
                <Cards />
              </Layout>
            }
          />
          <Route
            path="transfer"
            exact
            element={
              <Layout>
                <Transfer />
              </Layout>
            }
          />
          <Route
            path="compare"
            exact
            element={
              <Layout>
                <ComparePlans />
              </Layout>
            }
          />
          <Route
            path="crypto"
            exact
            element={
              <Layout>
                <Crypto />
              </Layout>
            }
          />
          <Route
            path="investing"
            exact
            element={
              <Layout>
                <Investing />
              </Layout>
            }
          />
          <Route
            path="aboutUs"
            exact
            element={
              <Layout>
                <AboutUs />
              </Layout>
            }
          />
          <Route
            path="help-support"
            exact
            element={
              <Layout>
                <NeedHand />
              </Layout>
            }
          />
          <Route
            path="help-support-details"
            exact
            element={
              <Layout>
                <HelpSupportMain />
              </Layout>
            }
          />
          <Route
            path="terms-conditions"
            exact
            element={
              <Layout>
                <Terms />
              </Layout>
            }
          />
          <Route
            path="rival-terms"
            exact
            element={
              <Layout>
                <RivalTerms />
              </Layout>
            }
          />
          <Route
            path="partners-terms"
            exact
            element={
              <Layout>
                <PartnersTerms />
              </Layout>
            }
          />
          <Route
            path="privacy-policy"
            exact
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            path="*"
            element={
              <Error />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;

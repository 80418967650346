// import React from "react";
// import { Link } from "react-router-dom";
// import { Container, Grid, Card, CardContent, Typography, Button, Box } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import Img1m from "../../assets/not_found.svg";

// // Custom styles
// const useStyles = makeStyles((theme) => ({
//     root: {
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: theme.palette.grey[100],
//     },
//     image: {
//         width: "100%",
//         maxWidth: "400px",
//         [theme.breakpoints.up("md")]: {
//             maxWidth: "600px",
//         },
//     },
//     card: {
//         textAlign: "center",
//         padding: theme.spacing(4),
//         boxShadow: theme.shadows[3],
//     },
//     heading: {
//         fontWeight: 700,
//         marginBottom: theme.spacing(2),
//     },
//     button: {
//         marginTop: theme.spacing(3),
//         borderRadius: "10px",
//         background:"#246BFD",
//         color:"#fff",
//         textTransform:"capitalize",
//     },
// }));

// function ErrorPage() {
//     const classes = useStyles();

//     return (
//         <Box className={classes.root}>
//             <Container>
//                 <Grid container spacing={4} alignItems="center" justifyContent="center">
//                     {/* Image Section */}
//                     <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
//                         <img src={Img1m} alt="404 Error" className={classes.image} />
//                     </Grid>

//                     {/* Content Section */}
//                     <Grid item xs={12} md={6}>
//                         <Card className={classes.card}>
//                             <CardContent>
//                                 <Typography variant="h4" className={classes.heading}>
//                                     Oh No! Error 404
//                                 </Typography>
//                                 <Typography variant="body1" color="textSecondary" paragraph>
//                                     Maybe Bigfoot has broken this page. Come back to the homepage.
//                                 </Typography>
//                                 <Button
//                                     component={Link}
//                                     to={process.env.PUBLIC_URL + "/"}
//                                     variant="contained"
                                  
//                                     className={classes.button}
//                                 >
//                                     Back to Homepage
//                                 </Button>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                 </Grid>
//             </Container>
//         </Box>
//     );
// }

// export default ErrorPage;



import React from 'react'
import ErrorImg from "../../assets/404.png";
import classes from './Error.module.css'
import { Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom';
const ErrorPage = () => {
    const navigate = useNavigate(); // Create navigate instance

    const handleGoToHome = () => {
      navigate('/'); // Redirect to the homepage (or root route)
    };
  return (
    <div className={classes.errorMain}>
        <div className={classes.errorOverlay}>
        <img src={ErrorImg} alt='404' className={classes.errorPic} />
        <Button className={classes.errorButton}>404 error</Button>
        <div className={classes.errorContent}>
        <p className={classes.errorHead}>We’ve lost this page</p>
        <p className={classes.errorDes}>Sorry, the page your are looking for doesn’t exist or has been removed.</p>
        </div>
        <Button className={classes.errorBack} onClick={handleGoToHome}>Go to Homepage</Button>
        </div>
    </div>
  )
}

export default ErrorPage

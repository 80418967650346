import React from 'react';
import classes from './Component28.module.css';
// import data from '../../asset-video/rival-flip.mp4';
import { FaAngleRight } from "react-icons/fa6";

const Component28 = () => {

    const data = "https://res.cloudinary.com/dwevyyuwo/video/upload/v1736510435/hxinjkfkt7lgixj1b5s6.mp4"
    return (
        <div className={classes.parent}>
            <div className={classes.main}>
                <div className={classes.left}>
                    <h2>What’s your type?</h2>
                    <p>Choose from an exclusive red or a sleek black card. Your spending has gotten spicy. <span>Apple Pay available.</span></p>
                   <a href='/compare' style={{textDecoration: "none",listStyle:"none",cursor:"pointer"}}><label>Compare plan’s <FaAngleRight color="#0894FF" size={12} /></label></a>
                </div>
                <div className={classes.right}>
                    <video
                        width="100%"
                        height="auto"
                        key={data}
                        muted
                        playsInline
                        style={{
                            outline: 'none',
                            pointerEvents: 'none',
                        }}
                        autoPlay
                        loop={true}
                    >
                        <source src={data} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default Component28;
import React, { useState, useEffect, useRef } from 'react';

import bank from '../../assets/bank-transfers.png';
import wallet from '../../assets/wallet-transfers.png';
import peer from '../../assets/peer-tranfers.png';
import card from '../../asset/rival-yellow-black.png';

import classes from './Component15.module.css';

const Component15 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Check screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    // Initial check and event listener for resizing
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.5 } // Adjust the threshold as needed
      );

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    } else {
      setIsVisible(true); // Ensure content is fully visible on mobile
    }
  }, [isMobile]);

  return (
    <div className={classes.transfers_parent_parent}>
      <div className={classes.transfers_parent}>
        <h2>
          How to transfer money <label>internationally from the lebenon</label>
        </h2>
        <div className={classes.transfers_child}>
          {[{ img: bank, title: 'Bank transfers', desc: 'Send and receive money through bank accounts (SWIFT & SEPA) accessibility. T&C’s apply.', marginTop: '85px' },
          { img: card, title: 'Card Transfers', desc: 'Transfer money in between Rival Card users', marginTop: '50px' },
          { img: peer, title: 'Peer transfers', desc: 'Pay your Rival friends instantly, no more excuses.', marginTop: '0' },
          { img: wallet, title: 'Wallet transfers', desc: 'Send money to local and international wallets in a split second, we are everywhere.', marginTop: '0' }].map((box, index) => (
            <div
              key={index}
              ref={containerRef}
              style={{
                position: 'relative',
                transform: isVisible || isMobile ? 'translateY(0)' : 'translateY(20px)',
                opacity: isVisible || isMobile ? 1 : 0,
                transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
              }}
              className={classes.transfers_child_boxes}
            >
              <img src={box.img} alt={box.title.toLowerCase()} style={{ marginBottom: '1.5rem' }} />
              <h3 style={{ marginTop: box.marginTop }}>{box.title}</h3>
              <p>{box.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component15;



// import React, { useState, useEffect, useRef } from 'react';

// import bank from '../../assets/bank-transfers.png'
// import wallet from '../../assets/wallet-transfers.png';
// import peer from '../../assets/peer-tranfers.png';
// import card from '../../asset/rival-yellow-black.png';

// import classes from './Component15.module.css'

// const Component15 = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         }
//       },
//       { threshold: 0.5 } // Adjust the threshold as needed
//     );

//     if (containerRef.current) {
//       observer.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         observer.unobserve(containerRef.current);
//       }
//     };
//   }, []);
//   return (
//     <div className={classes.transfers_parent_parent}>
//       <div className={classes.transfers_parent}>
//         <h2>How to transfer money <label>internationally from the lebenon</label></h2>
//         <div className={classes.transfers_child}>
//           <div

//             ref={containerRef}
//             style={{
//               position: 'relative',
//               transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//               opacity: isVisible ? 1 : 0,
//               transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//             }} className={classes.transfers_child_boxes}>
//             <img src={bank} alt="bank" style={{ marginBottom: "1.5rem" }} />
//             <h3 style={{ marginTop: "85px" }}>Bank transfers</h3>
//             <p>Send and receive money through bank accounts (SWIFT & SEPA) accessibility. T&C’s apply.</p>
//           </div>
//           <div
//             ref={containerRef}
//             style={{
//               position: 'relative',
//               transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//               opacity: isVisible ? 1 : 0,
//               transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//             }} className={classes.transfers_child_boxes}>
//             <img src={card} alt="card" style={{ marginBottom: "1.2rem" }} />
//             <h3 style={{ marginTop: "50px" }}>Card Transfers</h3>
//             <p>Transfer money in between Rival Card users</p>
//           </div>
//           <div
//             ref={containerRef}
//             style={{
//               position: 'relative',
//               transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//               opacity: isVisible ? 1 : 0,
//               transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//             }} className={classes.transfers_child_boxes}>
//             <img src={peer} alt="peer" />
//             <h3>Peer transfers</h3>
//             <p>Pay your Rival friends instantly, no more excuses.</p>
//           </div>
//           <div
//             ref={containerRef}
//             style={{
//               position: 'relative',
//               transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//               opacity: isVisible ? 1 : 0,
//               transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//             }} className={classes.transfers_child_boxes}>
//             <img src={wallet} alt="wallet" />
//             <h3>Wallet transfers</h3>
//             <p>Send money to local and international wallets in a split second, we are everywhere.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Component15;
import React from 'react';
import classes from './CardToggle.module.css';
import HideShowToggle from '../hide-show-toggle/HideShowToggle';

const CardToggle = () => {

    const data = [
        {
            heading: "Can I use my Rival card for online and in-store purchases?",
            description: "Yes, Rival cards (physical and virtual) can be used for online shopping and in-store purchases in more than 200,000,000 stores globally."
        },
        {
            heading: "How do I add funds to my Rival card?",
            description: 'After activating your card, you can add funds directly from your Rival app. Navigate to the "Card Account" section, select "Add Money to Card," choose the desired amount, and confirm the transaction.'
        },
        {
            heading: "Can I use my USDT to invest?",
            description: "Absolutely. Rival’s integration with USDT allows you to seamlessly invest using your crypto funds, providing flexibility and convenience for digital asset holders."
        }
    ];
    return (
        <div style={{ background: "#F5F5F7" }}>
            <div className={classes.compare_toggle}>
                <label>Frequently Asked Questions</label>
                <h2>Card FAQs</h2>
                <HideShowToggle data={data} />
            </div>
        </div>
    )
}

export default CardToggle;




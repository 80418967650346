import React, { createContext, useState } from "react";

// Create a Context
export const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [state, setState] = React.useState("Hello, World!");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpen2, setIsPopoverOpen2] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
    setIsPopoverOpen2(false);
  };
  const handlePopoverOpen1 = (event) => {
    setAnchorEl5(event.currentTarget);
    setIsPopoverOpen2(true);
    setIsPopoverOpen(false);
  };

  const handlePopoverClose = () => {
    setAnchorEl5(null);
    setIsPopoverOpen(false);
    setIsPopoverOpen2(false);
  };


  return (
    <MyContext.Provider
      value={{
        anchorEl,
        setAnchorEl,
        setAnchorEl5,
        handlePopoverOpen,
        handlePopoverOpen1,
        handlePopoverClose,
        isPopoverOpen,
        isPopoverOpen2
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;

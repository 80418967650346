/* global fbq */

export const Homes = () => {
  fbq("track", "Home");
};
export const Personals = () => {
  fbq("track", "Personal");
};
export const Cardss = () => {
  fbq("track", "Cards");
};
export const Transfers = () => {
  fbq("track", "Transfer");
};
export const ComparePlanss = () => {
  fbq("track", "ComparePlans");
};
export const Cryptos = () => {
  fbq("track", "Crypto");
};
export const Investings = () => {
  fbq("track", "Investing");
};
export const AboutUss = () => {
  fbq("track", "AboutUs");
};
export const NeedHands = () => {
  fbq("track", "NeedHand");
};
export const HelpSupportMains = () => {
  fbq("track", "HelpSupportMain");
};
export const Termss = () => {
  fbq("track", "Terms");
};
export const RivalTermss = () => {
  fbq("track", "RivalTerms");
};
export const PartnersTerms = () => {
  fbq("track", "PartnersTerms");
};
export const PrivacyPolicy = () => {
  fbq("track", "PrivacyPolicy");
};

import React, { useContext, useEffect } from "react";
import { MyContext } from "../../Context/AuthContext";
import Component1 from "../../build-component/component1/Component1";
import Component2 from "../../build-component/component2/Component2";
import Component3 from "../../build-component/component3/Component3";
import Component4 from "../../build-component/component4/Component4";
import Component5 from "../../build-component/component5/Component5";
import Component6 from "../../build-component/component6/Component6";
import Component24 from "../../build-component/component24/Component24";
import { Homes } from "../../Track";

const Home = () => {
  useEffect(() => {
    Homes();
  }, []);
  const { handlePopoverClose } = useContext(MyContext);
  return (
    <div onMouseEnter={handlePopoverClose}>
      <Component1 />
      <Component2 />
      <Component3 />
      <Component4 />
      <Component5 />
      <Component6 />
    </div>
  );
};

export default Home;

import React from 'react';
import classes from './CompareFeaturesChild.module.css';

const CompareFeaturesChild = ({ data }) => {
  return (
    <div className={classes.compare_features_child}>
      {data.map((item) => (
        <div key={item.id} className={classes.features_child_first}>
          {/* {item.image && <img style={{ item.widthh }} src={item.image} alt={item.topic} />} */}
          {item.image && <img style={{ width: item.widthh }} src={item.image} alt={item.topic} />}
          {item.topic && <h4>{item.topic}</h4>}
          {item.desc && <label>{item.desc}</label>}
        </div>
      ))}
    </div>
  )
}

export default CompareFeaturesChild;
import React from 'react';
import countries from '../../asset/modal-countries.png';

const Modal7 = () => {
    return (
        <div>
            <img src={countries} alt="countries" style={{ width: "100%", height: "auto" }} />
        </div>
    )
}

export default Modal7;




import React, { useState } from 'react';
import SwitchFirst from '../SwitchFirst';
import SwitchSecond from '../SwitchSecond';
import SwitchThird from '../SwitchThird';
import styles from './SwitchMain.module.css'; // Import the CSS Module

const SwitchMain = () => {
    const [activeComponent, setActiveComponent] = useState('Standard');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'Standard':
                return <SwitchThird />;
            case 'Plus':
                return <SwitchSecond />;
            case 'Pro':
                return <SwitchFirst />;
            default:
                return null;
        }
    };

    return (
        <div className={styles.switchMainContainer}>
            {/* Render the active component */}
            <div className={styles.componentContainer}>
                {renderComponent()}
            </div>

            {/* Buttons */}
            <div className={styles.buttonContainer}>
                <button
                    className={`${styles.button} ${activeComponent === 'Standard' ? styles.active : ''}`}
                    onClick={() => setActiveComponent('Standard')}
                >
                    Standard
                </button>
                <button
                    className={`${styles.button} ${activeComponent === 'Plus' ? styles.active : ''}`}
                    onClick={() => setActiveComponent('Plus')}
                >
                    Plus
                </button>
                <button
                    className={`${styles.button} ${activeComponent === 'Pro' ? styles.active : ''}`}
                    onClick={() => setActiveComponent('Pro')}
                >
                    Pro
                </button>
            </div>
        </div>
    );
};

export default SwitchMain;

import React from 'react';
import classes from './CompareFeatures.module.css';
import CompareFeaturesChild from '../compare-features-child/CompareFeaturesChild';

const CompareFeatures = ({title, data, hr}) => {
  return (
    <div className={classes.compare_features}>
        <h3>{title}</h3>
        {hr ?<hr className={classes.h_r} /> : '' }
        {/* <hr className={classes.h_r} /> */}
        <CompareFeaturesChild data={data} />
    </div>
  )
}

export default CompareFeatures;

import Phones from "../../asset/mobiles11.png"
import React from 'react'

const Component13 = () => {
  return (
    // <div style={{ marginTop: "5rem",padding:"60px 0px" }}>
    <div style={{ }}>
      <img src={Phones} alt='phone' width="100%" height="100%" />
    </div>
  )
}

export default Component13;
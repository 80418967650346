import React from 'react';
import classes from './Modal2.module.css';

const Modal2 = ({ desc, img }) => {
  return (
    <div className={classes.modal2_parent}>
      <p className={classes.modal2_desc} dangerouslySetInnerHTML={{ __html: desc }} />
      <img src={img} alt="world" className={classes.modal2_img} />
    </div>
  )
}

export default Modal2;
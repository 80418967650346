
import React, { useEffect, useRef, useState } from 'react';
import classes from './Component5.module.css';
import phone from '../../asset/black-phone.png';
import pay from '../../asset/apple-pay.png';

const Component5 = () => {
  const contentRef = useRef(null);
  const [scale, setScale] = useState(2.5);

  useEffect(() => {
    const handleScroll = () => {
      const rect = contentRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const visibleRatio = Math.min(Math.max(0, (windowHeight - rect.top) / rect.height), 1);

      if (visibleRatio >= 0 && visibleRatio <= 1) {
        const newScale = visibleRatio >= 0.3 ? 1 : 2.5 - visibleRatio * 5;
        setScale(newScale);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div ref={contentRef} className={classes.home_content5_parent}>
      <div
        className={classes.home_content5_child1}
        style={{
          transform: `scale(${scale})`,
          transition: 'transform 0.5s ease-out',
        }}
      >
        <img loading="lazy" src={"https://res.cloudinary.com/dwevyyuwo/image/upload/v1736247347/Test/ttuylc09siu55twxx1tc.avif"} alt="phone-image" />
      </div>
      <div className={classes.home_content5_child2}>
        <img loading="lazy" src={pay} alt="apple-pay" />
      </div>
      <p>
        We  have partnered up to bring Apple Pay to Rival, offering you a seamless,
        secure, and contactless payment experience. This integration highlights our
        commitment to innovative financial solutions.</p>
    </div>
  );
};

export default Component5;



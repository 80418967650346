import React, { useState, useEffect, useRef } from "react";
import classes from "./Component4.module.css";
// import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { FaChevronLeft } from "react-icons/fa6";
   import { FaChevronRight } from "react-icons/fa6";

// import mc1 from "../../asset/mc1.png";
// import mc2 from "../../asset/mc2.png";
// import mc3 from "../../asset/mc3.png";
// import mc4 from "../../asset/mc4.png";
// import mc5 from "../../asset/ImageWatch.png";
import { IoIosCloseCircle } from "react-icons/io";
// import cross from "../../asset/cross-new.png";
import world from "../../asset/world-plane.png";
import three from "../../asset/three-screen.png";
import cards from "../../asset/horizontal-cards.png";
import yellow from "../../asset/yellow-screens.png";
import pink from "../../asset/pink-phone.png";
import blue from "../../asset/blue-phone.png";
import white from "../../asset/white-screen.png";
import watches from "../../asset/watches.png";

import ModalTop from "../../modal/modal-top/ModalTop";
import Modal1 from "../../modal/modal1/Modal1";
import Modal2 from "../../modal/modal2/Modal2";
import Modal3 from "../../modal/modal3/Modal3";
import Modal4 from "../../modal/modal4/Modal4";
import Modal5 from "../../modal/modal5/Modal5";
import Modal6 from "../../modal/modal6/Modal6";
import Modal7 from "../../modal/modal7/Modal7";

const M1 = () => (
  <>
    <ModalTop ttl1="Transfer" ttl2="Send money anywhere, any place, anytime" />
    <Modal1
      desc={`At Rival, we don't just move money—we empower your financial freedom. <label>Whether you need to send money across the street or around the globe, our platform lets you transfer money seamlessly, locally and internationally.</label> No hidden fees. No delays. <label>Just instant, secure transfers you can count on. With 24/7 support and multi-currency options,</label> Rival puts you in full control of your finances. Fast. Reliable. Yours.`}
      img={world}
    />
    <Modal2
      desc={`Our app is built to make your financial transfers as easy as possible. With an intuitive design, you can send money locally or internationally in just a few taps. Manage your transfers, track transactions, and receive funds—all from the palm of your hand.`}
      img={three}
    />
    <Modal7 />
  </>
);
const M2 = () => (
  <>
    <ModalTop ttl1="Cards" ttl2="Your cards, your vibes" />
    <Modal1
      desc={`At Rival, we don't just move money—we empower your financial freedom. <label>Whether you need to send money across the street or around the globe, our platform lets you transfer money seamlessly, locally and internationally.</label> No hidden fees. No delays. <label>Just instant, secure transfers you can count on. With 24/7 support and multi-currency options,</label> Rival puts you in full control of your finances. Fast. Reliable. Yours.`}
      img={cards}
    />
    <Modal2
      desc={`Our cards puts you in full control. Instantly access card details, <label>add funds</label>, or <label>freeze</label> your card with a tap. Manage everything on your terms—right from your phone.`}
      img={yellow}
    />
    <Modal3
      text="With Apple Pay, get ready to start spending instantly in-store and online as soon as your card is issued. There's no need to wait for a physical card to be printed or worry about losing it."
      img={pink}
    />
  </>
);
const M3 = () => (
  <>
    <ModalTop ttl1="Support" ttl2="Having issues? " />
    <Modal4
      text="Our FAQ section is an available  resource that offers quick answers to common questions on topics like account management, payments, and troubleshooting. New FAQs are regularly added to keep users informed and supported."
      img={blue}
    />
    <Modal5
      title="Not enough?"
      img={white}
      desc="We provide customer support for all users"
      dates="24/7 365"
    />
  </>
);
const M4 = () => (
  <>
    <ModalTop ttl1="Invest" ttl2="Grow your wealth" />
    <Modal4
      text="Our FAQ section is an available  resource that offers quick answers to common questions on topics like account management, payments, and troubleshooting. New FAQs are regularly added to keep users informed and supported."
      img={blue}
    />
    <Modal5
      title="Not enough?"
      img={white}
      desc="We provide customer support for all users"
      dates="24/7 365"
    />
  </>
);
const M5 = () => (
  <>
    <ModalTop ttl1="Cards" ttl2="Your cards, your vibes" />
    <Modal6
      desc={`Our cards puts you in full control. Instantly access card details, <label>add funds</label>, or <label>freeze</label> your card with a tap. Manage everything on your terms—right from your phone.`}
      img={watches}
    />
    <Modal2
      desc={`Our cards puts you in full control. Instantly access card details, <label>add funds</label>, or <label>freeze</label> your card with a tap. Manage everything on your terms—right from your phone.`}
      img={yellow}
    />
    <Modal3
      text="With Apple Pay, get ready to start spending instantly in-store and online as soon as your card is issued. There's no need to wait for a physical card to be printed or worry about losing it."
      img={pink}
    />
  </>
);

const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(classes["modal-open"]);
    } else {
      document.body.classList.remove(classes["modal-open"]);
    }
    return () => {
      document.body.classList.remove(classes["modal-open"]);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleScroll = (event) => {
    event?.stopPropagation();
  };

  return (
    <div className={classes.modal} onClick={closeModal} onWheel={handleScroll}>
      <div
        className={classes.modal_content}
        onClick={(e) => e.stopPropagation()}
      >
        <IoIosCloseCircle className={classes.close_button} onClick={closeModal} />
        <div className={classes.modal_body}>{content}</div>
      </div>
    </div>
  );
};

const Component4 = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const containerRef = useRef(null);
  const openModal = (id) => {
    // Set a timeout before setting the content and opening the modal
    setTimeout(() => {
      switch (id) {
        case 1:
          setSelectedContent(<M1 />);
          break;
        case 2:
          setSelectedContent(<M2 />);
          break;
        case 3:
          setSelectedContent(<M3 />);
          break;
        case 4:
          setSelectedContent(<M4 />);
          break;
        case 5:
          setSelectedContent(<M5 />);
          break;
        default:
          setSelectedContent(null);
      }
      setModalOpen(true);
    }, 200); // Adjust the delay time (500ms) as needed
  };

  const closeModal = () => setModalOpen(false);

  // Scroll left function
  // const scrollLeft = () => {
  //   containerRef.current.scrollBy({ left: -400, behavior: "smooth" });
  // };

  // // Scroll right function
  // const scrollRight = () => {
  //   containerRef.current.scrollBy({ left: 400, behavior: "smooth" });
  // };

  // Scroll left function with looping
  const scrollLeft = () => {
    const carousel = containerRef.current;
    const scrollPosition = carousel.scrollLeft;

    if (scrollPosition <= 0) {
      // If at the beginning, scroll to the last image
      carousel.scrollTo({
        left: carousel.scrollWidth - carousel.clientWidth,
        behavior: "smooth",
      });
    } else {
      // Scroll normally left
      carousel.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  // Scroll right function with looping
  const scrollRight = () => {
    const carousel = containerRef.current;
    const scrollPosition = carousel.scrollLeft;
    const maxScroll = carousel.scrollWidth - carousel.clientWidth;

    if (scrollPosition >= maxScroll) {
      // If at the end, scroll to the first image
      carousel.scrollTo({ left: 0, behavior: "smooth" });
    } else {
      // Scroll normally right
      carousel.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const mc1 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505312/wm70fbi03jz1mdo7pvnl.avif";
  const mc2 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505440/f0juz5jt7gi9uptrhort.avif";
  const mc3 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505494/jvkhdzw97w6ipugk45bc.avif";
  const mc4 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505539/vsxp1fgst2wxrscmnlge.avif";
  const mc5 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505729/kesqami1w6jitfjlu0v9.avif";

  return (
    <div className={classes.home_content4_parent} >
      <h2>Get to know Rival</h2>
      <div className={classes.carousel_container}>
        <div className={classes.carousel} ref={containerRef}>
          <img src={mc1} alt="mc1" onClick={() => openModal(1)} />
          <img src={mc2} alt="mc2" onClick={() => openModal(2)} />
          <img src={mc3} alt="mc3" onClick={() => openModal(3)} />
          <img src={mc4} alt="mc4" onClick={() => openModal(4)} />
          <img src={mc5} alt="mc5" onClick={() => openModal(5)} />
          <img src={mc1} alt="mc1" onClick={() => openModal(1)} />
          <img src={mc2} alt="mc2" onClick={() => openModal(2)} />
          <img src={mc3} alt="mc3" onClick={() => openModal(3)} />
          <img src={mc4} alt="mc4" onClick={() => openModal(4)} />
          <img src={mc5} alt="mc5" onClick={() => openModal(5)} />
          <img src={mc1} alt="mc1" onClick={() => openModal(1)} />
          <img src={mc2} alt="mc2" onClick={() => openModal(2)} />
          <img src={mc3} alt="mc3" onClick={() => openModal(3)} />
          <img src={mc4} alt="mc4" onClick={() => openModal(4)} />
          <img src={mc5} alt="mc5" onClick={() => openModal(5)} />


        </div>
        <FaChevronLeft 
          color="black"
          className={`${classes.arrow} ${classes.left_arrow}`}
          onClick={scrollLeft}
          size={30} 
        />
     

        <FaChevronRight
          size={45}
          color="#000"
          className={`${classes.arrow} ${classes.right_arrow}`}
          onClick={scrollRight}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        content={selectedContent}
      />
    </div>
  );
};

export default Component4;

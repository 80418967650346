
import React, { useEffect, useRef } from 'react';
import './Component3.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis';

// import image1 from '../../assets/fullStandard.png'
// import image2 from '../../assets/fullPlus.png'
// import image3 from '../../assets/fullPro.png'

gsap.registerPlugin(ScrollTrigger);


const Component3 = () => {
    const workRef = useRef(null);

    useEffect(() => {
        const lenis = new Lenis({
            duration: 1.2,
        })


        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);

        lenis.on('scroll', ScrollTrigger.update);

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000);
        })

        const workInfoItems = document.querySelectorAll('.sc-work-photo-item');
        workInfoItems.forEach((item, index) => {
            item.style.zIndex = workInfoItems.length - index;
        });

        const animation = gsap.to('.sc-work-photo-item:not(:last-child)', {
            clipPath: 'inset(0px 0px 100% 0px)',
            stagger: 0.5,
            ease: 'none',
        })

        ScrollTrigger.create({
            trigger: workRef.current,
            start: 'top top',
            end: 'bottom bottom',
            animation: animation,
            scrub: 1
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        }
    }, []);

    const image1 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504890/voupwbmjb383byzxk9gs.avif";

    const image2 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505078/kwnibdsbzgza6lcwdt0v.avif";

    const image3 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1737468552/fullPro_s0tv7t.png";

    return (
        <section className="sc-work" ref={workRef}>
            <div className="sc-work-left">
                <div className="sc-work-info">
                    <span className="sc-title1">Explore plans</span>
                    <label className="sc-title2">Standard</label>
                    <p className="sc-para"><span>Unlock the future of finance with Rival's Standard Plan for just $6/month! </span> Dive into the world
                        of seamless payments with a card that makes spending as easy as cash. Say goodbye to complicated processes and
                        hello to secure, hassle-free transactions designed to fit your everyday needs. <span>Perfect for first-time users and
                            crypto enthusiasts alike, the Standard Plan is your gateway to a smarter, more innovative financial journey.</span></p>
                    <div className="sc-container">
                        <button className="sc-button"
                            onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                        >Get the app</button>
                        <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className="sc-link">Learn more</span></a>

                    </div>
                </div>
                <div className="sc-work-info">
                    <span className="sc-title1">Explore plans</span>
                    <label className="sc-title2" style={{ color: "#246BFD" }}>Plus</label>
                    <p className="sc-para"><span>Elevate your everyday spending with Rival’s Plus Plan for just $10/month.</span> Enjoy exclusive access
                        to a card for seamless payments, <span>Whether through Apple Pay, Google Pay, or your own physical card.</span> With dedicated
                        account manager support, the Plus Plan is your key to effortless and innovative financial freedom.</p>
                    <div className="sc-container">
                        <button className="sc-button"
                            onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                        >Get the app</button>
                        <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className="sc-link">Learn more</span></a>
                    </div>
                </div>
                <div className="sc-work-info">
                    <span className="sc-title1">Explore plans</span>
                    <label className="sc-title2" style={{ color: "#D8051A" }}>Pro</label>
                    <p className="sc-para"><span>Unlock ultimate financial flexibility with Rival’s Pro Plan for $13/month.</span> Get a
                        card for seamless spending, access IBANs for global transactions, and enjoy 24/7 support from dedicated account managers.
                        The Pro Plan is designed for those who demand premium features and unparalleled convenience. <span>Upgrade now and take your
                            finances to the next level!</span></p>
                    <div className="sc-container">
                        <button className="sc-button"
                            onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                        >Get the app</button>
                        <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className="sc-link">Learn more</span></a>
                    </div>
                </div>
            </div>
            <div className="sc-work-right">
                <div className="sc-work-right-bl">
                    <div className="sc-work-photo">
                        {[image1, image2, image3].map((src, index) => (
                            <div className="sc-work-photo-item" key={index}>
                                <img src={src} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Component3;


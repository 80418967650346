import React, { useState, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import classes from "./Styles.module.css";
import videoSrc from "../../asset-video/three-rivals.mp4";

import gradient from "../../asset/gradient-circle.png";
// import purple from '../../asset/purple-circle.png';
// import pink from '../../asset/pink-circle.png';
// import lite from '../../asset/lite-pink-circle.png';
import black from "../../asset/black-circle.png";
import green from "../../asset/green-circle.png";
import cream from "../../asset/cream-circle.png";
import blue from "../../asset/blue-circle.png";

function Model({ modelPath }) {
  const { scene } = useGLTF(modelPath);
  return <primitive object={scene} dispose={null} />;
}

useGLTF.preload("/gold-card.glb");
useGLTF.preload("/black-today.glb");
useGLTF.preload("/blue-card.glb");

const SwitchFirst = () => {
  const [displayMode, setDisplayMode] = useState("video"); // 'video' or 'model'
  const [currentModel, setCurrentModel] = useState("/Airplane.glb");
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    if (videoRef.current) videoRef.current.pause();
  };

  return (
    <div className={classes.switch_animation}>
      {displayMode === "video" ? (
        <video
          width="100%"
          height="auto"
          ref={videoRef}
          key={videoSrc}
          muted
          onEnded={handleVideoEnd}
          autoPlay
          playsInline
          // className={classes.vdo}
          style={{
            outline: 'none',
            height: '65vh',
            pointerEvents: 'none',
            paddingBottom: '50px',
            paddingTop: '55px'
          }}
        // style={{ outline: 'none', width: "100%", maxWidth: "500px" }}
        >
          <source
            // src={
            //   "https://res.cloudinary.com/dwevyyuwo/video/upload/v1736339884/wsr7pug1usohag9ukalt.mp4"
            // }
            src={
              "https://res.cloudinary.com/dwevyyuwo/video/upload/v1736427193/uyhvqitvvikqoxtwrrbt.mp4"
            }
            type="video/mp4"
          />
        </video>
      ) : (
        // <div>
        <div style={{ width: "100vw", height: "80vh" }}>
          {/* <Canvas
            camera={{
              position: [0, 0, 3], // Adjust position to zoom in closer to the model
              fov: 26, // Field of view, reduce for a tighter zoom
            }}
          >
            <Environment preset="studio" />
            <OrbitControls
              enableZoom={false} // Disable zoom functionality
              enablePan={false}
            />
            <Model modelPath={currentModel} />
          </Canvas> */}
        </div>
      )}

    </div>
  );
};

export default SwitchFirst;










// import React, { useState, useRef } from "react";
// import { Canvas } from "@react-three/fiber";
// import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
// import classes from "./Styles.module.css";
// import videoSrc from "../../asset-video/three-rivals.mp4";

// import gradient from "../../asset/gradient-circle.png";
// // import purple from '../../asset/purple-circle.png';
// // import pink from '../../asset/pink-circle.png';
// // import lite from '../../asset/lite-pink-circle.png';
// import black from "../../asset/black-circle.png";
// import green from "../../asset/green-circle.png";
// import cream from "../../asset/cream-circle.png";
// import blue from "../../asset/blue-circle.png";

// function Model({ modelPath }) {
//   const { scene } = useGLTF(modelPath);
//   return <primitive object={scene} dispose={null} />;
// }

// useGLTF.preload("/gold-card.glb");
// useGLTF.preload("/black-today.glb");
// useGLTF.preload("/blue-card.glb");

// const SwitchFirst = () => {
//   const [displayMode, setDisplayMode] = useState("video"); // 'video' or 'model'
//   const [currentModel, setCurrentModel] = useState("/Airplane.glb");
//   const videoRef = useRef(null);

//   const handleVideoEnd = () => {
//     if (videoRef.current) videoRef.current.pause();
//   };

//   return (
//     <div className={classes.switch_animation}>
//       {displayMode === "video" ? (
//         <video
//           width="100%"
//           height="auto"
//           ref={videoRef}
//           key={videoSrc}
//           muted
//           onEnded={handleVideoEnd}
//           autoPlay
//           playsInline
//           className={classes.vdo}
//           style={{
//             outline: 'none',
//             pointerEvents: 'none', // Prevent interaction
//           }}
//         // style={{ outline: 'none', width: "100%", maxWidth: "500px" }}
//         >
//           <source src={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736339884/wsr7pug1usohag9ukalt.mp4"} type="video/mp4" />
//         </video>
//       ) : (
//         // <div>
//         <div style={{ width: "100vw", height: "80vh" }}>
//           <Canvas
//             camera={{
//               position: [0, 0, 3], // Adjust position to zoom in closer to the model
//               fov: 26, // Field of view, reduce for a tighter zoom
//             }}
//           >
//             <Environment preset="studio" />
//             <OrbitControls
//               enableZoom={false} // Disable zoom functionality
//               enablePan={false}
//             />
//             <Model modelPath={currentModel} />
//           </Canvas>
//         </div>
//       )}

//       <div className={classes.switch_animation_btn_parent}>
//         <img
//           src={gradient}
//           alt="gradient"
//           className={classes.images}
//           onClick={() => setDisplayMode("video")}
//         />

//         {/* Model Buttons */}
//         <img
//           src={cream}
//           alt="cream"
//           className={classes.images}
//           onClick={() => {
//             setDisplayMode("model");
//             setCurrentModel("/gold-card.glb");
//           }}
//         />
//         <img
//           src={black}
//           alt="black"
//           className={classes.images}
//           onClick={() => {
//             setDisplayMode("model");
//             setCurrentModel("/black-today.glb");
//           }}
//         />
//         <img
//           src={blue}
//           alt="blue"
//           className={classes.images}
//           onClick={() => {
//             setDisplayMode("model");
//             setCurrentModel("/blue-card.glb");
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default SwitchFirst;

import React, { useState, useEffect, useRef } from 'react';
import classes from './ComparePhone.module.css';
import InternationalBtn from '../../components/international-btn/InternationalBtn';

const ComparePhone = ({ image, title, description, price, buttonText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Check if the screen size is small
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Check screen size on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      // Disable observer on small screens
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [isSmallScreen]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
        opacity: isVisible || isSmallScreen ? 1 : 0,
        transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
      }}
      className={classes.compare_phone}
    >
      <img src={image} alt={title} />
      <h3>{title}</h3>
      <span>{description}</span>
      <label>{price}</label>
      <InternationalBtn title={buttonText} />
    </div>
  );
};

export default ComparePhone;




// import React, { useState, useEffect, useRef } from 'react';
// import classes from './ComparePhone.module.css';
// import InternationalBtn from '../../components/international-btn/InternationalBtn';

// const ComparePhone = ({ image, title, description, price, buttonText }) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         }
//       },
//       { threshold: 0.5 } // Adjust the threshold as needed
//     );

//     if (containerRef.current) {
//       observer.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         observer.unobserve(containerRef.current);
//       }
//     };
//   }, []);
//   return (
//     <div

//       ref={containerRef}
//       style={{
//         position: 'relative',
//         transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//         opacity: isVisible ? 1 : 0,
//         transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//       }} className={classes.compare_phone}>
//       <img src={image} alt={title} />
//       <h3>{title}</h3>
//       <span>{description}</span>
//       <label>{price}</label>
//       <InternationalBtn title={buttonText} />
//     </div>
//   )
// }

// export default ComparePhone;








import React, { useState, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls, useGLTF } from '@react-three/drei';
import classes from './Styles.module.css';
// import videoSrc from '../../asset-video/six-cards.mp4';

import gradient from '../../asset/gradient-circle.png';
import purple from '../../asset/purple-circle.png';
import pink from '../../asset/pink-circle.png';
import lite from '../../asset/lite-pink-circle.png';
import black from '../../asset/black-circle.png';
import green from '../../asset/green-circle.png';
import cream from '../../asset/cream-circle.png';
import blue from '../../asset/blue-circle.png';
import yellow from '../../asset/yellow-circle.png';

function Model({ modelPath }) {
    const { scene } = useGLTF(modelPath);
    return <primitive object={scene} dispose={null} />;
}

useGLTF.preload('/gold-card.glb');
useGLTF.preload('/black-card.glb');
useGLTF.preload('/blue-card.glb');
useGLTF.preload('/pink-today.glb');
useGLTF.preload('/green-card.glb');
useGLTF.preload('/yellow-card.glb');
useGLTF.preload('/dark-pink-card.glb');
useGLTF.preload('/purple-card.glb');

const SwitchThird = () => {
    const [displayMode, setDisplayMode] = useState('video');
    const [currentModel, setCurrentModel] = useState('/Airplane.glb');
    const videoRef = useRef(null);

    const handleVideoEnd = () => {
        if (videoRef.current) videoRef.current.pause();
    };

    const videoSrc = "https://res.cloudinary.com/dwevyyuwo/video/upload/v1736507292/eydh5ifjksi51gcyyt93.mp4";

    return (
        <div className={classes.switch_animation}>
            {displayMode === 'video' ? (
                <video
                    width="100%"
                    height="auto"
                    ref={videoRef}
                    key={videoSrc}
                    muted
                    onEnded={handleVideoEnd}
                    autoPlay
                    playsInline
                    style={{
                        outline: 'none',
                        height: '65vh',
                        pointerEvents: 'none',
                    }}
                // className={classes.vdo}
                >
                    <source src={videoSrc} type="video/mp4" />
                </video>
            ) : (
                <div style={{ width: '100vw', height: '100vh' }}>
                    {/* <Canvas camera={{
                        position: [0, 0, 3], // Adjust position to zoom in closer to the model
                        fov: 26,            // Field of view, reduce for a tighter zoom
                    }}>
                        <Environment preset="studio" />
                        <OrbitControls enableZoom={false} // Disable zoom functionality
                            enablePan={false} />
                        <Model modelPath={currentModel} />
                    </Canvas> */}
                </div>
            )}

        </div>
    );
};

export default SwitchThird;













// import React, { useState, useRef } from 'react';
// import { Canvas } from '@react-three/fiber';
// import { Environment, OrbitControls, useGLTF } from '@react-three/drei';
// import classes from './Styles.module.css';
// import videoSrc from '../../asset-video/six-cards.mp4';

// import gradient from '../../asset/gradient-circle.png';
// import purple from '../../asset/purple-circle.png';
// import pink from '../../asset/pink-circle.png';
// import lite from '../../asset/lite-pink-circle.png';
// import black from '../../asset/black-circle.png';
// import green from '../../asset/green-circle.png';
// import cream from '../../asset/cream-circle.png';
// import blue from '../../asset/blue-circle.png';
// import yellow from '../../asset/yellow-circle.png';

// function Model({ modelPath }) {
//     const { scene } = useGLTF(modelPath);
//     return <primitive object={scene} dispose={null} />;
// }

// useGLTF.preload('/gold-card.glb');
// useGLTF.preload('/black-card.glb');
// useGLTF.preload('/blue-card.glb');
// useGLTF.preload('/pink-today.glb');
// useGLTF.preload('/green-card.glb');
// useGLTF.preload('/yellow-card.glb');
// useGLTF.preload('/dark-pink-card.glb');
// useGLTF.preload('/purple-card.glb');

// const SwitchThird = () => {
//     const [displayMode, setDisplayMode] = useState('video');
//     const [currentModel, setCurrentModel] = useState('/Airplane.glb');
//     const videoRef = useRef(null);

//     const handleVideoEnd = () => {
//         if (videoRef.current) videoRef.current.pause();
//     };

//     return (
//         <div className={classes.switch_animation}>
//             {displayMode === 'video' ? (
//                 <video
//                     width="100%"
//                     height="auto"
//                     ref={videoRef}
//                     key={videoSrc}
//                     muted
//                     onEnded={handleVideoEnd}
//                     autoPlay
//                     playsInline
//                     style={{
//                         outline: 'none',
//                         height: '65vh',
//                         pointerEvents: 'none',
//                       }}
//                     // className={classes.vdo}
//                 >
//                     <source src={videoSrc} type="video/mp4" />
//                 </video>
//             ) : (
//                 <div style={{ width: '100vw', height: '100vh' }}>
//                     {/* <Canvas camera={{
//                         position: [0, 0, 3], // Adjust position to zoom in closer to the model
//                         fov: 26,            // Field of view, reduce for a tighter zoom
//                     }}>
//                         <Environment preset="studio" />
//                         <OrbitControls enableZoom={false} // Disable zoom functionality
//                             enablePan={false} />
//                         <Model modelPath={currentModel} />
//                     </Canvas> */}
//                 </div>
//             )}

//         </div>
//     );
// };

// export default SwitchThird;













// import React, { useState, useRef } from 'react';
// import { Canvas } from '@react-three/fiber';
// import { Environment, OrbitControls, useGLTF } from '@react-three/drei';
// import classes from './Styles.module.css';
// import videoSrc from '../../asset-video/six-cards.mp4';

// import gradient from '../../asset/gradient-circle.png';
// import purple from '../../asset/purple-circle.png';
// import pink from '../../asset/pink-circle.png';
// import lite from '../../asset/lite-pink-circle.png';
// import black from '../../asset/black-circle.png';
// import green from '../../asset/green-circle.png';
// import cream from '../../asset/cream-circle.png';
// import blue from '../../asset/blue-circle.png';
// import yellow from '../../asset/yellow-circle.png';

// function Model({ modelPath }) {
//     const { scene } = useGLTF(modelPath);
//     return <primitive object={scene} dispose={null} />;
// }

// useGLTF.preload('/gold-card.glb');
// useGLTF.preload('/black-card.glb');
// useGLTF.preload('/blue-card.glb');
// useGLTF.preload('/pink-today.glb');
// useGLTF.preload('/green-card.glb');
// useGLTF.preload('/yellow-card.glb');
// useGLTF.preload('/dark-pink-card.glb');
// useGLTF.preload('/purple-card.glb');

// const SwitchThird = () => {
//     const [displayMode, setDisplayMode] = useState('video');
//     const [currentModel, setCurrentModel] = useState('/Airplane.glb');
//     const videoRef = useRef(null);

//     const handleVideoEnd = () => {
//         if (videoRef.current) videoRef.current.pause();
//     };

//     return (
//         <div className={classes.switch_animation}>
//             {displayMode === 'video' ? (
//                 <video
//                     width="100%"
//                     height="auto"
//                     ref={videoRef}
//                     key={videoSrc}
//                     muted
//                     onEnded={handleVideoEnd}
//                     autoPlay
//                     playsInline
//                     style={{
//                         outline: 'none',
//                         pointerEvents: 'none', // Prevent interaction
//                     }}
//                     className={classes.vdo}
//                 >
//                     <source src={videoSrc} type="video/mp4" />
//                 </video>
//             ) : (
//                 <div style={{ width: '100vw', height: '100vh' }}>
//                     <Canvas camera={{
//                         position: [0, 0, 3], // Adjust position to zoom in closer to the model
//                         fov: 26,            // Field of view, reduce for a tighter zoom
//                     }}>
//                         <Environment preset="studio" />
//                         <OrbitControls enableZoom={false} // Disable zoom functionality
//                             enablePan={false} />
//                         <Model modelPath={currentModel} />
//                     </Canvas>
//                 </div>
//             )}

//             <div className={classes.switch_animation_btn_parent}>
//                 <img
//                     src={gradient}
//                     alt="gradient"
//                     className={classes.images}
//                     onClick={() => setDisplayMode('video')}
//                 />

//                 {/* Model Buttons */}
//                 {/* <img
//                     src={cream}
//                     alt="cream"
//                     style={{ width: "30px", height: "auto" }}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/gold-card.glb');
//                     }}
//                 /> */}
//                 <img
//                     src={lite}
//                     alt="lite"
//                     className={classes.images}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/pink-today.glb');
//                     }}
//                 />
//                 <img
//                     src={blue}
//                     alt="blue"
//                     className={classes.images}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/blue-card.glb');
//                     }}
//                 />
//                 <img
//                     src={yellow}
//                     alt="yellow"
//                     className={classes.images}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/yellow-card.glb');
//                     }}
//                 />
//                 <img
//                     src={purple}
//                     alt="purple"
//                     className={classes.images}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/purple-card.glb');
//                     }}
//                 />
//                 <img
//                     src={pink}
//                     alt="pink"
//                     className={classes.images}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/dark-pink-card.glb');
//                     }}
//                 />
//                 <img
//                     src={green}
//                     alt="green"
//                     className={classes.images}
//                     onClick={() => {
//                         setDisplayMode('model');
//                         setCurrentModel('/green-card.glb');
//                     }}
//                 />
//             </div>
//         </div>
//     );
// };

// export default SwitchThird;
import React, { useContext, useEffect } from "react";
import { MyContext } from "../../Context/AuthContext";
import mobilecard from "../../asset-video/new-saving-video.mp4";
// import mobilecard from '../../asset-video/phone-vertical.mp4';
import classes from "./Personal.module.css";
import SimpleAnimation from "../../components/simple-animation/SimpleAnimation";

import shield from "../../asset/privacy-shield.png";
import circle from "../../asset/privacy-circle.png";
import adjust from "../../asset/privacy-adjust.png";
import lock from "../../asset/security-lock.png";
import alien from "../../asset/security-alien.png";
import thumb from "../../asset/security-thumb.png";

import Component7 from "../../build-component/component7/Component7";
import Component4 from "../../build-component/component4/Component4";
import Component24 from "../../build-component/component24/Component24";
import Component8 from "../../build-component/component8/Component8";
import Component9 from "../../build-component/component9/Component9";
import Component18 from "../../build-component/component18/Component18";
import PrivacySecurity from "../../components/privacy-security/PrivacySecurity";
import Component27 from "../../build-component/component27/Component27";
import { Personals } from "../../Track";
const Personal = () => {
  const { handlePopoverClose } = useContext(MyContext);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
    Personals();
  }, []);

  return (
    <div onMouseEnter={handlePopoverClose}>
      {/* <SimpleAnimation data={mobilecard} loop={true} style={{ marginBottom: "-6px" }} /> */}
      {/* <div className={classes.personelVideSection}>
        <SimpleAnimation data={mobilecard} loop={true} style={{ marginBottom: "-6px" }} />
      </div> */}
      <Component27 />
      <Component7 />
      <Component24 />
      <Component4 />
      <Component8 />
      <Component9 />
      <Component18 />
      <PrivacySecurity
        title="Privacy"
        desc1="<span>Rival is committed to safeguarding your privacy with ISO-200071 certification and GDPR compliance.</span> We ensure 
      that your personal and financial information is securely stored and never shared without your explicit consent."
        desc2="<span>At Rival, we value transparency. Your data is used solely to enhance your experience and deliver our services.</span> 
      We never sell or misuse your personal information, adhering strictly to global privacy standards."
        desc3="<span>Rival empowers you to take control of your privacy.</span> Manage your personal data, review permissions, and update 
      preferences directly through the app, ensuring a seamless and secure experience."
        img1={shield}
        img2={circle}
        img3={adjust}
      />
      <PrivacySecurity
        title="Security"
        desc1="Rival employs state-of-the-art encryption, passcode verification, and biometric authentication to safeguard your account. 
        <span>Our systems monitor real-time transactions to ensure your funds and personal information are always secure.</span>"
        desc2="<span>Rival's fraud detection systems utilize advanced machine learning to identify and mitigate suspicious activities.</span> Our 
        dedicated fraud prevention team conducts in-depth investigations to protect your account and ensure a secure financial experience."
        desc3="<span>Rival prioritizes your privacy and data security, adhering to ISO-200071 standards and GDPR compliance.</span> We never compromise on 
        protecting your personal information and ensure that all transactions are conducted in a secure environment."
        img1={lock}
        img2={alien}
        img3={thumb}
      />
    </div>
  );
};

export default Personal;

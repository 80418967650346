import React from 'react';
import classes from './Component23.module.css';
import vdo from '../../asset-video/crypto-coin.mp4';
import img from '../../asset/crypto-loop.png'
import SimpleAnimation from '../../components/simple-animation/SimpleAnimation';

const Component23 = () => {
    return (
        <div className={classes.component_22}>
            <img src={img} alt="first-img" className={classes.img_style} />
            <SimpleAnimation data={vdo} loop={true} style={{ position: "relative", zIndex: 1 }} />
            <p><span>Investing in</span> crypto <span>on Rival means more than just holding</span> digital assets<span>—it’s about</span> unlocking <span>the
                potential of</span> decentralized finance. <span>With advanced security, instant deposits, and future plans to
                    expand into a wider range of cryptocurrencies, Rival gives you the tools to</span> capitalize <span>on the
                        growth of</span> blockchain technology.</p>
        </div>
    )
}

export default Component23;
import React from 'react';
import classes from './AboutUsBanner.module.css';

import InternationalBtn from '../../../components/international-btn/InternationalBtn';


const AboutUsBanner = () => {
    return (
        <div className={classes.about_banner}>
            <div className={classes.absolute}>
                <h2>We Change The Way You do Money</h2>
                {/* <p>Grow your crypto portfolio at your convenience. T&C's apply.</p> */}
                <InternationalBtn title="Spend your crypto" style={{ color: "#000", background: "#fff" }} />
            </div>
        </div>
    )
}

export default AboutUsBanner;
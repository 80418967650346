import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import classes from "./HelpSupportHeader.module.css";

const HelpSupportHeader = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.p_parent}>
      <div className={classes.parent}>
        <div className={classes.left}>
          <div className={classes.left_first}>
            <FaArrowAltCircleLeft
              onClick={() => navigate(-1)}
              style={{ width: "30px", height: "auto", marginTop: "10px",cursor: "pointer" }}
            />
            <div className={classes.first_right}>
              <h2>Need a hand?</h2>
              <p>Just pop your question below to get an answer.</p>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          {/* <div className={classes.need_help_input}>
            <input type="text" placeholder="Tell us what you're looking for" />
            <AiOutlineSearch size={24} />
          </div> */}
        </div>
        {/* <div className={classes.topicsText}>
        <h2>Topics</h2>
      </div> */}
        {/* <div className={classes.right}>
        sdf
      </div> */}
      </div>
      <p style={{ fontSize: "26px", color: "#191C1F", fontWeight: "600", marginTop: "5rem" }}>Topics</p>
    </div>
  );
};

export default HelpSupportHeader;
import React, { useContext, useEffect } from "react";
import { MyContext } from "../../Context/AuthContext";
import Component29 from "../../build-component/component29/Component29";

import Component10 from "../../build-component/component10/Component10";
import CompareFeatures from "../../build-component/compare-section/compare-features/CompareFeatures";
import SimpleAnimation from "../../components/simple-animation/SimpleAnimation";
import Toggle from "../../components/toggle/Toggle";

import img1 from "../../assets/line-horizontal.png";
import img2 from "../../assets/Vector.png";
import img3 from "../../assets/asset-two.png";
import img4 from "../../assets/tick-blue.png";
import img5 from "../../assets/tick-red.png";
import img6 from "../../assets/color-box.png";
import img7 from "../../assets/percent-refresh.png";
import img8 from "../../assets/comp.png";
import img9 from "../../assets/gradient-bag.png";
import img10 from "../../assets/pay-apple.png";
import img11 from "../../assets/head-phones.png";
import img12 from "../../assets/24by7.png";
import img13 from "../../assets/dollar-tick.png";
import img14 from "../../assets/lose-profit.png";
import img15 from "../../assets/save-dollar.png";
import img16 from "../../assets/percent-bag.png";
import img17 from "../../assets/black-heart.png";
import img18 from "../../asset/black-white-t.png";
import img19 from "../../asset/zero-per.png";
import img20 from "../../asset/one-per.png";
import img21 from "../../asset/two-per.png";
import img22 from "../../asset/three-per.png";
import { ComparePlanss } from "../../Track";
import vdo from "../../assets-video/card-vertical-vid.mp4";

const first = [
  {
    id: 1,
    image: img1,
    topic: "minimum deposit",
    widthh: "30px",
  },
  {
    id: 2,
    image: img1,
    topic: "minimum deposit",
    widthh: "30px",
  },
  {
    id: 3,
    image: img1,
    topic: "minimum deposit",
    widthh: "30px",
  },
  {
    id: 4,
    image: img2,
    topic: "International transfer",
    desc: "Standard fees",
  },
  {
    id: 5,
    image: img2,
    topic: "International transfer",
    desc: "10% discount",
  },
  {
    id: 6,
    image: img2,
    topic: "International transfer",
    desc: "20% discount",
  },
  {
    id: 7,
    image: img3,
    topic: "Card type",
    desc: "Virtual",
  },
  {
    id: 8,
    image: img3,
    topic: "Card type",
    desc: "Virtual/Physical",
  },
  {
    id: 9,
    image: img3,
    topic: "Card type",
    desc: "Virtual/Physical",
  },
  {
    id: 10,
    image: img1,
    topic: "Exclusive cards",
    widthh: "30px",
  },
  {
    id: 11,
    image: img4,
    topic: "Exclusive cards",
    desc: "Available",
  },
  {
    id: 12,
    image: img5,
    topic: "Exclusive cards",
    desc: "Available",
  },
  {
    id: 13,
    image: img6,
    topic: "Card colors",
    desc: "5 colors",
  },
  {
    id: 14,
    image: img6,
    topic: "Card colors",
    desc: "6 colors",
  },
  {
    id: 15,
    image: img6,
    topic: "Card colors",
    desc: "7 colors",
  },
  {
    id: 16,
    image: img1,
    topic: "Cashback",
    widthh: "30px",
  },
  {
    id: 17,
    image: img1,
    topic: "Cashback",
    widthh: "30px",
  },
  {
    id: 18,
    image: img7,
    topic: "Cashback",
    desc: "Up to 1.5%",
  },
  {
    id: 19,
    image: img8,
    topic: "Card limit",
    desc: "$1000/month",
  },
  {
    id: 20,
    image: img8,
    topic: "Card limit",
    desc: "$10,000/month",
  },
  {
    id: 21,
    image: img8,
    topic: "Card limit",
    desc: "$30,000/month",
  },
  {
    id: 22,
    image: img1,
    topic: "Free virtual card",
    widthh: "30px",
  },
  {
    id: 23,
    image: img1,
    topic: "Free virtual card",
    widthh: "30px",
  },
  {
    id: 24,
    image: img9,
    topic: "Free virtual card",
    desc: "2/year",
  },
  {
    id: 23,
    image: img1,
    topic: "Apple pay",
    widthh: "30px",
  },
  {
    id: 24,
    image: img10,
    topic: "Apple pay",
  },
  {
    id: 25,
    image: img10,
    topic: "Apple pay",
    desc: "Yes",
  },
  {
    id: 26,
    image: img11,
    topic: 'Priority support',
    desc: "Office hour's"
  },
  {
    id: 27,
    image: img12,
    topic: "Priority support",
    desc: "24/7 Support",
  },
  {
    id: 28,
    image: img12,
    topic: "Priority support",
    desc: "24/7 Support",
  },
];

const second = [
  {
    id: 1,
    image: img13,
    topic: "Savings",
    desc: "Up to 1.09%/AER Gross **(Variable)",
  },
  {
    id: 2,
    image: img13,
    topic: "Savings",
    desc: "Up to 2.09%/AER Gross **(Variable)",
  },
  {
    id: 3,
    image: img13,
    topic: "Savings",
    desc: "Up to 3.84%/AER Gross **(Variable)",
  },
  {
    id: 4,
    image: img14,
    topic: "Investing",
    desc: "Standard fees",
  },
  {
    id: 5,
    image: img14,
    topic: "Investing",
    desc: "5% Discount**",
  },
  {
    id: 6,
    image: img14,
    topic: "Investing",
    desc: "10% Discount**",
  },
  {
    id: 7,
    image: img15,
    topic: "Loans",
  },
  {
    id: 8,
    image: img15,
    topic: "Loans",
    desc: "Priority",
  },
  {
    id: 9,
    image: img15,
    topic: "Loans",
    desc: "High Priority",
  },
  {
    id: 10,
    image: img16,
    topic: "Loan Ticket size",
  },
  {
    id: 11,
    image: img16,
    topic: "Loan Ticket size",
    desc: "Priority",
  },
  {
    id: 12,
    image: img16,
    topic: "Loan Ticket size",
    desc: "High Priority",
  },
];

const third = [
  {
    id: 1,
    image: img17,
    topic: 'Referral Invite',
    desc: '$30',
  },
  {
    id: 2,
    image: img17,
    topic: 'Referral Invite',
    desc: '$40',
  },
  {
    id: 3,
    image: img17,
    topic: 'Referral Invite',
    desc: '$50',
  },
];

const fourth = [
  {
    id: 1,
    image: img8,
    topic: "Card Limit",
    desc: "1",
  },
  {
    id: 2,
    image: img8,
    topic: "Card Limit",
    desc: "2",
  },
  {
    id: 3,
    image: img8,
    topic: "Card Limit",
    desc: "5",
  },
  {
    id: 4,
    image: img18,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$2,000.00 Per Account",
  },
  {
    id: 5,
    image: img18,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$20,000.00 Per Account",
  },
  {
    id: 6,
    image: img18,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$150,000.00 Per Account",
  },
  {
    id: 7,
    image: img18,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$2,000.00 Per Account",
  },
  {
    id: 8,
    image: img18,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$20,000.00 Per Account",
  },
  {
    id: 9,
    image: img18,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$150,000.00 Per Account",
  },
  {
    id: 10,
    image: img19,
    topic: "Fee add money to card",
    desc: "0$ - 100$",
  },
  {
    id: 11,
    image: img19,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "0$ - $2,000",
  },
  {
    id: 12,
    image: img19,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "0$ - $4,000",
  },
  {
    id: 13,
    image: img20,
    topic: "Fee add money to card",
    desc: "$151 - $300",
  },
  {
    id: 14,
    image: img20,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$2,001 - $3,000",
  },
  {
    id: 15,
    image: img20,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$4,001 - $10,000",
  },
  {
    id: 16,
    image: img21,
    topic: "Fee add money to card",
    desc: "$1,001 - $2,000",
  },
  {
    id: 17,
    image: img21,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$10,001 - $20,000",
  },
  {
    id: 18,
    image: img21,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "20,001 - $150,000",
  },
  {
    id: 19,
    image: img22,
    topic: "Fee add money to card",
    desc: "$1,001 - $2,000",
    widthh: "85px",
  },
  {
    id: 20,
    image: img22,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$10,001 - $20,000",
    widthh: "85px",
  },
  {
    id: 21,
    image: img22,
    topic: "Deposit Into Card (USDT+USDT)",
    desc: "$20,001 - $150,000",
    widthh: "85px",
  },

]

const fifth = [
  // {
  //   id: 1,
  //   image: img8,
  //   topic: 'Card Limit',
  //   desc: '1',
  // },
  // {
  //   id: 2,
  //   image: img8,
  //   topic: 'Card Limit',
  //   desc: '2',
  // },
  // {
  //   id: 3,
  //   image: img8,
  //   topic: 'Card Limit',
  //   desc: '5',
  // },
  // {
  //   id: 4,
  //   image: img18,
  //   topic: 'Deposit Into Card (USDT+USDT)',
  //   desc: '$2,000.00 Per Account',
  // },
  // {
  //   id: 5,
  //   image: img18,
  //   topic: 'Deposit Into Card (USDT+USDT)',
  //   desc: '$20,000.00 Per Account',
  // },
  // {
  //   id: 6,
  //   image: img18,
  //   topic: 'Deposit Into Card (USDT+USDT)',
  //   desc: '$150,000.00 Per Account',
  // },
  {
    id: 7,
    image: img18,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$2,000.00 Per Account',
  },
  {
    id: 8,
    image: img18,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$20,000.00 Per Account',
  },
  {
    id: 9,
    image: img18,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$150,000.00 Per Account',
  },
  {
    id: 10,
    image: img19,
    topic: 'Fee add money to card',
    desc: '0$ - 100$',
  },
  {
    id: 11,
    image: img19,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '0$ - $2,000',
  },
  {
    id: 12,
    image: img19,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '0$ - $4,000',
  },
  {
    id: 13,
    image: img20,
    topic: 'Fee add money to card',
    desc: '$151 - $300',
  },
  {
    id: 14,
    image: img20,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$2,001 - $3,000',
  },
  {
    id: 15,
    image: img20,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$4,001 - $10,000',
  },
  {
    id: 16,
    image: img21,
    topic: 'Fee add money to card',
    desc: '$1,001 - $2,000',
  },
  {
    id: 17,
    image: img21,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$10,001 - $20,000',
  },
  {
    id: 18,
    image: img21,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '20,001 - $150,000',
  },
  {
    id: 19,
    image: img22,
    topic: 'Fee add money to card',
    desc: '$1,001 - $2,000',
    widthh: '85px',
  },
  {
    id: 20,
    image: img22,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$10,001 - $20,000',
    widthh: '85px',
  },
  {
    id: 21,
    image: img22,
    topic: 'Deposit Into Card (USDT+USDT)',
    desc: '$20,001 - $150,000',
    widthh: '85px',
  },

]

const ComparePlans = () => {
  const { handlePopoverClose } = useContext(MyContext);
  useEffect(() => {
    ComparePlanss();
  }, []);
  return (
    <div onMouseEnter={handlePopoverClose}>
      <Component10 />
      <div style={{ marginBottom: "2rem" }}></div>
      <CompareFeatures title="Summary" data={first} />
      <CompareFeatures title="Financial" data={second} />
      <CompareFeatures title="Referral" data={third} />
      <CompareFeatures title="Rival Basics" data={fourth} />
      <CompareFeatures title="Rival Basics" data={fourth} />
      <Component29 />
      <SimpleAnimation
        data={vdo}
        loop={false}
        style={{ marginBottom: "-3px" }}
      />
      <Toggle />
    </div>
  );
};

export default ComparePlans;

import React from 'react';
import classes from './Component10.module.css';
import { FaAngleRight } from 'react-icons/fa';
import ComparePhone from '../../components/compare-phone/ComparePhone';

// import img1 from '../../assets/i-pro.png';
// import img2 from '../../assets/i-plus.png';
// import img3 from '../../assets/i-standard.png';

const data = [
  {
    id: 1,
    image: "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506691/pzkux22fk9k3wp0kheo0.avif",
    title: 'Standard',
    description: 'Start your journey with standard.',
    price: 'From $5.99/mo',
    buttonText: 'Join Now'
  },
  {
    id: 2,
    image: "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506636/wgunq3zopw8uevpp3jum.avif",
    title: 'Plus',
    description: 'Power up with plus',
    price: 'From $9.99/mo',
    buttonText: 'Join Now'
  },
  {
    id: 3,
    image: "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506537/xraija6ejfgpmrcynilg.avif",
    title: 'Pro',
    description: 'Tailored for the pros.',
    price: 'From $12.99/mo',
    buttonText: 'Join Now'
  }
]

const Component10 = () => {
  return (
    <div className={classes.compare_top}>
      <h1>Compare Rival plans</h1>
      {/* <p>Get help choosing. <a href="">Chart with a specialist <FaAngleRight className={classes.icons} /></a></p> */}
      <p>Get help choosing.</p>
      <div className={classes.compare_top_child}>
        {data.map((item) => (
          <ComparePhone
            key={item.id}
            image={item.image}
            title={item.title}
            description={item.description}
            price={item.price}
            buttonText={item.buttonText}
          />
        ))}
      </div>
    </div>
  )
}

export default Component10;
import React, { useEffect, useRef, useState } from "react";
import classes from "./Component17.module.css";
import phone from "../../assets/i-plus.png";

const Component17 = () => {
  const contentRef = useRef(null);
  const [scale, setScale] = useState(2.5);

  useEffect(() => {
    const handleScroll = () => {
      const rect = contentRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const visibleRatio = Math.min(
        Math.max(0, (windowHeight - rect.top) / rect.height),
        1
      );

      if (visibleRatio >= 0 && visibleRatio <= 1) {
        const newScale = visibleRatio >= 0.3 ? 1 : 2.5 - visibleRatio * 5;
        setScale(newScale);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div ref={contentRef} className={classes.home_content17_parent}>
      <h2>Rival Account</h2>
      <div
        className={classes.home_content17_child1}
        style={{
          transform: `scale(${scale})`,
          transition: "transform 0.5s ease-out",
        }}
      >
        <img loading="lazy" src={phone} alt="phone-image" />
      </div>
      <p> At Rival, we believe that financial growth should be accessible to everyone, regardless
        of their subscription tier. That’s why all our subscribers<span>—Standard, Plus, and Pro—will have
          access to our investing platform.</span> Whether you’re looking to trade stocks, invest in cryptocurrencies,
        or build a diverse portfolio, Rival ensures that you have the tools and opportunities you need to
        succeed.</p>
    </div>
  );
};

export default Component17;

// import React from 'react';
// import classes from './Component17.module.css';

// import plus from '../../assets/i-plus.png';

// const Component17 = () => {
//   return (
//     <div className={classes.component17_parent}>
//       <h2>Portfolio</h2>
//       <img src={plus} alt="plus" />
//       <p>In publishing and graphic design, Lorem ipsum is a placeholder text
//         commonly used to demonstrate <label>the visual form of a document or a
//           typeface without relying on meaningful content. Lorem ipsum may be used
//           as a placeholder before the final copy is available</label></p>
//     </div>
//   )
// }

// export default Component17

import React, { useState, useEffect, useRef } from 'react';
import classes from './Component8.module.css';

// import watch from '../../asset/watch1.png';
// import watch2 from '../../asset/watch2.png';

const Component8 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Check if the screen size is small
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Check screen size on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      // Disable observer on small screens
      setIsVisible(true);
      return;
    }


    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [isSmallScreen]);



  return (
    <div className={classes.component8_parent}>
      <h1>Pay with Apple & Google pay</h1>
      <p>
        No need to wait for your physical card, you could begin spending with
        your card instantly by your Apple & Google Wallet
      </p>
      <div className={classes.component8_child}>
        <div
          ref={containerRef}
          style={{
            position: 'relative',
            transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
            opacity: isVisible || isSmallScreen ? 1 : 0,
            transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
          }}
          className={classes.component8_child_left}
        >
          <p>
            <label>Use your</label> iPhone, Apple Watch, <label>or other Apple
            devices, whether</label> online, in-store, <label>or</label> in
            apps.
          </p>
          <button
            onClick={() =>
              window.open(
                'https://apps.apple.com/tr/app/rival-finance/id6468575208',
                '_blank'
              )
            }
            style={{ cursor: 'pointer' }}
          >
            Learn more
          </button>
          <img src={"https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505991/g0s8f50ijmzup1kmhnus.avif"} alt="square-watch" />
        </div>
        <div
          ref={containerRef}
          style={{
            position: 'relative',
            transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
            opacity: isVisible || isSmallScreen ? 1 : 0,
            transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
          }}
          className={classes.component8_child_right}
        >
          <p>
            <label>With your</label> Android <label>phone,</label> smartwatch
            <label>, or other compatible devices, you can pay effortlessly</label>{' '}
            online, in-store, <label>or</label> within apps.
          </p>
          <button
            onClick={() =>
              window.open(
                'https://apps.apple.com/tr/app/rival-finance/id6468575208',
                '_blank'
              )
            }
            style={{ cursor: 'pointer' }}
          >
            Learn more
          </button>
          <img src={"https://res.cloudinary.com/dwevyyuwo/image/upload/v1736506076/jesiuct5qkgnaqdol4lm.avif"} alt="circle-watch" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default Component8;


// import React, { useState, useEffect, useRef } from 'react';
// import classes from './Component8.module.css';

// import watch from '../../asset/watch1.png';
// import watch2 from '../../asset/watch2.png';

// const Component8 = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         }
//       },
//       { threshold: 0.5 } // Adjust the threshold as needed
//     );

//     if (containerRef.current) {
//       observer.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         observer.unobserve(containerRef.current);
//       }
//     };
//   }, []);
//   return (
//     <div className={classes.component8_parent}>
//       <h1>Pay with Apple & Google pay</h1>
//       <p>No need to wait for your physical card, you could begin spending with your card instantly by your Apple & Google Wallet</p>
//       <div className={classes.component8_child}>
//         <div
//           ref={containerRef}
//           style={{
//             position: 'relative',
//             transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//             opacity: isVisible ? 1 : 0,
//             transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//           }}

//           className={classes.component8_child_left}>
//           <p><label>Use your</label> iPhone, Apple Watch, <label>or other Apple devices, whether</label> online, in-store, <label>or</label> in apps.</p>
//           <button
//             onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')} style={{ cursor: "pointer" }}
//           >Learn more</button>
//           <img src={watch} alt="square-watch" />
//         </div>
//         <div

//           ref={containerRef}
//           style={{
//             position: 'relative',
//             transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//             opacity: isVisible ? 1 : 0,
//             transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//           }} className={classes.component8_child_right}>
//           <p><label>With your</label> Android <label>phone,</label> smartwatch<label>, or other compatible devices, you can pay effortlessly</label> online, in-store, <label>or</label> within apps.</p>
//           <button
//             onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')} style={{ cursor: "pointer" }}
//           >Learn more</button>
//           <img src={watch2} alt="circle-watch" height="100%" />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Component8;




// import React, { useState } from 'react';
// import classes from './HideShowCrypto.module.css';
// import plus from '../../asset/plus-icon.png';
// import minus from '../../asset/blue-minus.png';

// const HideShowCrypto = ({ data }) => {
//     const [activeIndex, setActiveIndex] = useState(0);

//     const handleToggle = (index) => {
//         console.log("ncadjbvi",index)
//         setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
//     };


//     return (
//         <div className={classes.component_parent}>
//             {data.map((item, index) => (
//                 <ToggleComponent
//                     key={index}
//                     heading={item.heading}
//                     description={item.description}
//                     index={index}
//                     isActive={activeIndex === index}
//                     onClick={()=>handleToggle(index)}
//                 />
//             ))}
//         </div>
//     );
// };


// const ToggleComponent = ({ heading, description, index, isActive, onClick }) => {
//     console.log("dhvdilvhvdvu",index)
//     return (
//         <div className={classes.component_container}>
//             <div className={classes.heading_container}>
//                 <h3 className={classes.heading_heading}>{heading}</h3>
//                 <button onClick={() => onClick(index)} className={classes.toggle_button}>
//                     {isActive ?
//                         <img style={{ width: '30px', height: '30px' }} src={minus} alt="minus" /> :
//                         <img style={{ width: '30px', height: '30px' }} src={plus} alt="plus" />}
//                 </button>
//             </div>
//             {isActive && <p className={classes.description}>{description}</p>}
//             <hr style={{ color: "#00000040" }} />
//         </div>
//     );
// };




// export default HideShowCrypto;

import React, { useState } from 'react';
import classes from './HideShowCrypto.module.css';

import minus from '../../asset/blue-minus.png';
import plus from '../../asset/plus-icon.png'; // Assuming you have a plus image

const HideShowCrypto = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleToggle = (index) => {
        console.log("ncadjbvi", index)
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className={classes.component_parent}>
            {data.map((item, index) => (
                <ToggleComponent
                    key={index}
                    heading={item.heading}
                    description={item.description}
                    index={index}
                    isActive={activeIndex === index}
                    onClick={() => handleToggle(index)}
                    plus={plus}
                    minus={minus}
                />
            ))}
        </div>
    );
};

const ToggleComponent = ({ heading, description, index, isActive, onClick,minus,plus }) => {
    return (
        <div className={classes.component_container}>
            <div className={classes.heading_container}>
                <h3 className={classes.heading_heading}>{heading}</h3>
                <button onClick={() => onClick(index)} className={classes.toggle_button}>
                    <img 
                        style={{ width: '30px', height: '30px' }} 
                        src={isActive ? minus : plus} // Dynamically changing icon
                        alt={isActive ? "minus" : "plus"} 
                    />
                </button>
            </div>
            {isActive && <p className={classes.description}>{description}</p>}
            <hr style={{ color: "#00000040" }} />
        </div>
    );
};

export default HideShowCrypto;



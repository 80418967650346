import React from 'react';
import classes from './Component6.module.css';

import SimpleAnimation from '../../components/simple-animation/SimpleAnimation';
import vdo from '../../asset-video/coin-vertical.mp4';

const Component6 = () => {
  return (
    <div className={classes.component6_parent}>
      <div className={classes.component6_texts}>
        <label className={classes.component6_text1}>Investment</label>
        <p className={classes.component6_text2}>Invest with Rival</p>
      </div>
      <SimpleAnimation data={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736244818/Test/suhoydddbhykydghcbn9.mp4"} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} />
    </div>
  )
}

export default Component6;

import React from "react";
import classes from "./AboutUsBoard.module.css";

import worldd from "../../../asset/worldd.png";

const AboutUsBoard = () => {
  return (
    <div className={classes.parent}>
      <div className={classes.left}>
        <h1>THE TEAM</h1>
        <p>
          The rival team’s reputation rests on a solid foundation of tenacity
          and adaptability. Over time, they have weathered numerous challenges,
          each one pushing them to unify and become more formidable. By
          regularly testing themselves against stiff competition, they’ve
          learned to transform setbacks into catalysts for improving their
          strategy. Their unyielding drive for excellence shines through in
          rigorous practice regimens, dynamic gameplay, and the unwavering sense
          of unity among all members. The depth of their collective
          experience—earned through countless hours of training and
          high-pressure matches—continues to shape their identity as an imposing
          force capable of meeting any challenge head-on.
        </p>
      </div>
      <div className={classes.right}>
        <img src={worldd} alt="worldd" />
      </div>
    </div>
  );
};

export default AboutUsBoard;
